var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as signalR from '@aspnet/signalr';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../../../../src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./authentication.service";
export class SignalRService {
    constructor(_authenService) {
        this._authenService = _authenService;
        this.connections = [];
        this.hubs = {};
        this.delayStarters = [];
        this.connected = false;
        this.maxRetryTimes = 10;
        this.wait = ms => new Promise(r => setTimeout(r, ms));
        // this.init();
    }
    initCommonHub() {
        return __awaiter(this, void 0, void 0, function* () {
            this.hubs['CommonHub'] = this.createConnection('CommonHub');
            yield this.startConnection(this.hubs['CommonHub']);
            this.autoReconnect(this.hubs['CommonHub']);
            while (this.delayStarters.length > 0) {
                this.delayStarters.pop()();
            }
            this.connected = true;
        });
    }
    initNotificationHub() {
        return __awaiter(this, void 0, void 0, function* () {
            this.hubs['NotificationHub'] = this.createConnection('NotificationHub');
            yield this.startConnection(this.hubs['NotificationHub']);
            this.autoReconnect(this.hubs['NotificationHub']);
            while (this.delayStarters.length > 0) {
                this.delayStarters.pop()();
            }
            this.connected = true;
        });
    }
    // async init() {
    //     this.hubs['CommonHub'] = this.createConnection('CommonHub');
    //     this.hubs['NotificationHub'] = this.createConnection('NotificationHub');
    //     // this.hubs['ConsistencyHub'] = this.createConnection('ConsistencyHub');
    //     await this.startConnection(this.hubs['CommonHub']);
    //     await this.startConnection(this.hubs['NotificationHub']);
    //     // await this.startConnection(this.hubs['ConsistencyHub']);
    //     this.autoReconnect(this.hubs['CommonHub']);
    //     this.autoReconnect(this.hubs['NotificationHub']);
    //     // this.autoReconnect(this.hubs['ConsistencyHub']);
    //     while (this.delayStarters.length > 0) {
    //         this.delayStarters.pop()();
    //     }
    //     this.connected = true;
    // }
    start(topic, viewCode, callback) {
        if (!topic) {
            topic = 'CommonHub';
        }
        if (!this.connected) {
            this.delayStarters.push(() => {
                this.subscribeViewCode(this.hubs[topic], viewCode, callback);
            });
        }
        else {
            this.subscribeViewCode(this.hubs[topic], viewCode, callback);
        }
    }
    stop() {
        this._hubConnection.stop();
    }
    subscribeViewCode(hubConnection, viewCode, callback) {
        if (!this.viewCodeSubs) {
            this.viewCodeSubs = [{ viewCode, callback }];
            this.subscribe(hubConnection, viewCode, callback);
        }
        if (this.viewCodeSubs.findIndex(x => x.viewCode === viewCode) === -1) {
            this.viewCodeSubs.push({ viewCode, callback });
            this.subscribe(hubConnection, viewCode, callback);
        }
    }
    unSubscribeViewCode(topic, viewCode) {
        if (!topic) {
            topic = 'CommonHub';
        }
        const hubConnection = this.hubs[topic];
        if (this.viewCodeSubs) {
            this.viewCodeSubs = this.viewCodeSubs.filter(x => x.viewCode !== viewCode);
            this.unSubscribe(hubConnection, viewCode);
        }
    }
    createConnection(topic) {
        return new signalR.HubConnectionBuilder()
            .withUrl(`${environment.apiDomain.notificationHub}/${topic}`, { accessTokenFactory: () => this._authenService.getAccessToken() })
            .build();
    }
    autoReconnect(hubConnection) {
        hubConnection.onclose(() => __awaiter(this, void 0, void 0, function* () {
            yield this.startConnection(hubConnection);
        }));
    }
    startConnection(hubConnection, retryCount = 1) {
        return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
            Object.defineProperty(WebSocket, 'OPEN', { value: 1, });
            hubConnection
                .start().then(() => {
                resolve(true);
            })
                .catch((err) => {
                if (this.maxRetryTimes - retryCount > 0) {
                    return this.wait(retryCount * retryCount * 1000)
                        .then(this.startConnection.bind(this, hubConnection, ++retryCount))
                        .then(resolve)
                        .catch(reject);
                }
                return reject(err);
            });
        }));
    }
    subscribe(hubConnection, viewCode, callback) {
        if (viewCode) {
            hubConnection.invoke('JoinRoom', viewCode).catch(err => console.error(err.toString()));
            switch (viewCode) {
                case 'CommonHub':
                    hubConnection.on('BroadcastMessageTypeId', (messageTypeId) => {
                        callback({ messageTypeId: messageTypeId });
                    });
                    break;
                case 'NotificationHub':
                    hubConnection.on('BroadcastMessage', (msg) => {
                        callback({ userId: null, msg: msg });
                    });
                    hubConnection.on('SendMessageToUserId', (userId, msg) => {
                        callback({ userId, msg: msg });
                    });
                    break;
                default:
                    hubConnection.on(viewCode, (data) => {
                        callback(data);
                    });
                    break;
            }
        }
    }
    unSubscribe(hubConnection, viewCode) {
        if (viewCode) {
            hubConnection.invoke('leaveRoom', viewCode).catch(err => console.error(err.toString()));
            hubConnection.off(viewCode);
        }
    }
}
SignalRService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SignalRService_Factory() { return new SignalRService(i0.ɵɵinject(i1.AuthenticationService)); }, token: SignalRService, providedIn: "root" });
