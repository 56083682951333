var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { UserService } from './user.service';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../../../../src/environments/environment';
import { SignalRService } from './signalr.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./user.service";
import * as i3 from "./authentication.service";
import * as i4 from "./signalr.service";
export class PermissionService extends BaseService {
    constructor(http, injector, _userService, _authenticationService, _signalRService) {
        super(http, injector, `${environment.apiDomain.coreEndPoint}/AzPermissions`);
        this._userService = _userService;
        this._authenticationService = _authenticationService;
        this._signalRService = _signalRService;
        this.storage = sessionStorage;
        this.setupSignalr = false;
        this._signalRService.initCommonHub();
        if (!this.setupSignalr) {
            this._signalRService.start(null, 'CommonHub', this.refreshPermissions.bind(this));
            this.setupSignalr = true;
        }
    }
    refreshPermissions() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log('refreshPermissions');
            for (const key in this.storage) {
                if (key.indexOf('AUTHORIZATION') >= 0) {
                    yield this.storage.removeItem(key);
                }
                if (key.indexOf('htMenu') >= 0) {
                    this.storage.removeItem(key);
                }
            }
            yield this.getsRoleAssignedToUserId();
        });
    }
    isAuthorized(service, permission) {
        return new Promise((resolve, reject) => {
            const currUser = this._authenticationService.getCurrentUser();
            if (currUser.issuperuser === 'True') {
                resolve(true);
            }
            this.getPermissions(service).then(permissions => {
                const isAuthorized = permissions.includes(permission);
                resolve(isAuthorized);
            }, error => {
                reject(false);
            });
        });
    }
    getPermissions(service) {
        return new Promise((resolve, reject) => {
            const url = `${this.serviceUri}/GetsPermissionByMaAzService/${service}`;
            const key = `AUTHORIZATION.${service.toUpperCase()}`;
            const storageItem = this.storage.getItem(key);
            if (storageItem) {
                const result = JSON.parse(storageItem);
                resolve(result);
            }
            else {
                this.defaultGet(url).then(response => {
                    this.storage.setItem(key, JSON.stringify(response.data));
                    resolve(response.data);
                }, error => {
                    reject();
                });
            }
        });
    }
    getsRoleAssignedToUserId() {
        return new Promise((resolve, reject) => {
            const url = `${environment.apiDomain.coreEndPoint}/AzRoles/GetsAssignedToUserId`;
            const key = `AUTHORIZATION.ROLES`;
            const storageItem = this.storage.getItem(key);
            if (storageItem) {
                const result = JSON.parse(storageItem);
                resolve(result);
            }
            else {
                this.defaultGet(url).then(response => {
                    this.storage.setItem(key, JSON.stringify(response.data));
                    resolve(response.data);
                }, error => {
                    reject();
                });
            }
        });
    }
    isLaSiAdmin() {
        return new Promise((resolve, reject) => {
            const currUser = this._authenticationService.getCurrentUser();
            if (currUser.issuperuser === 'True') {
                resolve(true);
            }
            else {
                this.getsRoleAssignedToUserId().then(asRoles => {
                    const isAuthorized = asRoles.includes('LaSi.Admin') || asRoles.includes('LaSi.Manager') || asRoles.includes('LaSi.Viewer');
                    resolve(isAuthorized);
                }, error => {
                    reject(false);
                });
            }
        });
    }
}
PermissionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i3.AuthenticationService), i0.ɵɵinject(i4.SignalRService)); }, token: PermissionService, providedIn: "root" });
