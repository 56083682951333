import { ActivatedRoute, Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class CustomRouterService {
    constructor(_activatedRoute, _router) {
        this._activatedRoute = _activatedRoute;
        this._router = _router;
    }
    openChildPage(childPageRouter, navExtra = {}) {
        this._router.navigate([childPageRouter], navExtra);
    }
    openPage(pageRouter, navExtra = {}) {
        this._router.navigate([pageRouter], navExtra);
    }
    backToParentPage() {
        this._router.navigate(['..'], { relativeTo: this._activatedRoute });
    }
    openCreatePage(navExtra = {}) {
        this._router.navigate(['./create'], navExtra);
    }
    openEditPage(id, navExtra = {}) {
        this._router.navigate([`./edit/${id}`], navExtra);
    }
    parseFragment(fragmentString) {
        if (!fragmentString || fragmentString === '') {
            return {};
        }
        const urlSearchParam = fragmentString.split('&');
        const obj = {};
        for (const index in urlSearchParam) {
            const item = urlSearchParam[index].split('=');
            obj[item[0]] = item[1];
        }
        return obj;
    }
    getFragment(routerUrl) {
        if (routerUrl.indexOf('#') === -1) {
            return '';
        }
        return routerUrl.split('#')[1];
    }
    fragmentToString(fragmentObj) {
        let op = '';
        for (const key in fragmentObj) {
            op += `&${key}=${fragmentObj[key]}`;
        }
        if (op !== '') {
            op = op.substr(1);
        }
        return op;
    }
    appendFragment(fragment, key, value) {
        const frag = this.parseFragment(fragment);
        frag[key] = value.toString();
        return this.fragmentToString(frag);
    }
    appendFragmentObject(fragment, key, value) {
        fragment[key] = value.toString();
        return fragment;
    }
    removeFragmentObject(fragment, key) {
        if (fragment[key]) {
            delete fragment[key];
        }
        return fragment;
    }
    removeFragment(fragment, key) {
        const frag = this.parseFragment(fragment);
        if (frag[key]) {
            delete frag[key];
        }
        return this.fragmentToString(frag);
    }
    getUrlWithoutParamAndFragment() {
    }
    fragmentIsChildOf(frag1, frag2) {
        const frag1Obj = this.parseFragment(frag1);
        const frag2Obj = this.parseFragment(frag2);
        for (const ind in frag1Obj) {
            if (frag1Obj[ind] !== frag2Obj[ind]) {
                return false;
            }
        }
        return true;
    }
}
CustomRouterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomRouterService_Factory() { return new CustomRouterService(i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i1.Router)); }, token: CustomRouterService, providedIn: "root" });
