var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CONFIG_CALENDAR_VIETNAMESE } from '../../../../../../src/app/config/calendar.config';
import { EventEmitter, Injector, ElementRef, OnInit } from '@angular/core';
import { ComponentBase } from './component-base';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidationSummaryComponent } from '../../components/validation-summary/validation-summary.component';
import { NotifierService } from '../../services/notifier.service';
import { Dialog } from 'primeng/dialog';
import { CommonService } from '../../services/common.service';
import { SvUser } from '../../models/sv-user';
import { AuthenticationService } from '../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
export class SecondPageEditBase extends ComponentBase {
    constructor(_baseService, _injector) {
        super();
        this._baseService = _baseService;
        this._injector = _injector;
        this.vi = CONFIG_CALENDAR_VIETNAMESE;
        this.isShow = false;
        this.submitting = false;
        this.continueAdding = true;
        this.invalid = false;
        this.itemDetail = {};
        this.formSchema = [];
        this.uploadedFiles = [];
        this.popupHeader = '';
        this.formGroup = new FormGroup({});
        this.arrTrangThai = [
            { label: 'Sử dụng', value: 1 },
            { label: 'Không Sử dụng', value: 2 },
        ];
        this.currentUser = new SvUser();
        this.openPopupDelete = false;
        this.closePopup = new EventEmitter();
        this._authenticationService = this._injector.get(AuthenticationService);
        this._notifierService = this._injector.get(NotifierService);
        this._translateService = this._injector.get(TranslateService);
        this.popupId = this._injector.get(CommonService).guid();
        this.currentUser = this._authenticationService.getCurrentUser();
    }
    ngOnInit() {
        // this._masterDataService.getConfigByCode('SizeOfFile')
        //     .then(response => {
        //         if (response) {
        //             this.limitSize = Number(response) * 1000 * 1000;
        //         }
        //     });
        this.continueAdding = true;
    }
    convertDataToOptions(options, data, fieldName) {
        if (data.length > 0) {
            data.map(item => options.push({ label: item[fieldName], value: item.id }));
        }
    }
    saveAndClose() {
        this.continueAdding = false;
        this.save();
    }
    save() {
        this.submitting = true;
        if (this.formGroup.invalid) {
            this.showValidateMessage();
            this.scrollToTop();
            return;
        }
        const rs1 = this.onBeforeSave();
        if ((typeof rs1 === 'boolean' && rs1) || typeof rs1 !== 'boolean') {
            if (this.itemDetail['id']) {
                this.onUpdate();
            }
            else {
                this.onInsert();
            }
        }
    }
    showValidateMessage() {
        this.validationSummary.showValidationSummary();
        this.submitting = false;
    }
    onBeforeSave() {
        return true;
    }
    onAfterSave() {
    }
    showPopup(data = null) {
        return __awaiter(this, void 0, void 0, function* () {
            this.isShow = true;
            yield this.onShowPopup(data);
            setTimeout(() => {
                const viewRef = document.getElementById(this.popupId);
                if (viewRef != null) {
                    const input = viewRef.querySelector('input');
                    if (input) {
                        input.focus();
                    }
                    else {
                        const texateara = viewRef.querySelector('textarea');
                        if (texateara) {
                            texateara.focus();
                        }
                    }
                }
                if (this.dialog) {
                    this.dialog.center();
                }
            }, 500);
        });
    }
    onShowPopup(data) {
        this.onReset();
        this.onBeforeShowPopUp();
        if (data) {
            this._baseService.getDetail(data)
                .then((response) => __awaiter(this, void 0, void 0, function* () {
                this.itemDetail = response.data;
                this.onAfterShowPopUp();
            }), error => {
                this._notifierService.showHttpUnknowError();
            });
        }
    }
    onReset() {
        this.itemDetail = {};
    }
    onAfterShowPopUp() {
    }
    onBeforeShowPopUp() {
    }
    closePopupMethod(data) {
        this.isShow = false;
        this.closePopup.next(data);
    }
    getMaxWidthDialog() {
        return (window.innerHeight - 200).toString() + 'px';
    }
    onInsert() {
        this._baseService.post(this.itemDetail)
            .then(response => {
            this.itemDetail.id = response.data;
            if (this.continueAdding) {
                this.resetForm();
                this.itemDetail.id = 0;
            }
            else {
                this.closePopupMethod(true);
            }
            this._notifierService.showInsertDataSuccess();
            this.onAfterSave();
            this.submitting = false;
        }, error => {
            this._notifierService.showInsertDataFailed();
            this.submitting = false;
        });
    }
    onUpdate() {
        this._baseService.put(this.itemDetail['id'].toString(), this.itemDetail)
            .then(response => {
            this.closePopupMethod(true);
            this._notifierService.showUpdateDataSuccess();
            this.onAfterSave();
            this.submitting = false;
        }, error => {
            this._notifierService.showUpdateDataFailed();
            this.submitting = false;
        });
    }
    cancel() {
        this.closePopupMethod(null);
    }
    buildFormGroup(formGroup, formSchema) {
        const formGroupD = formGroup || this.formGroup;
        const formSchemaD = formSchema || this.formSchema;
        for (let i = 0; i < formSchemaD.length; i++) {
            const schemaItem = formSchemaD[i];
            const formControl = new FormControl('');
            if (schemaItem.control && schemaItem.control.defaultValue !== undefined) {
                this.itemDetail[schemaItem.field] = schemaItem.control.defaultValue;
                if (!schemaItem.visible && schemaItem.control.defaultValue) {
                    formControl.setValue(schemaItem.control.defaultValue);
                }
            }
            if (schemaItem.validators && schemaItem.validators.required) {
                formControl.setValidators(Validators.required);
            }
            formGroupD.addControl(schemaItem.field, formControl);
        }
    }
    resetForm() {
        this.validationSummary.resetErrorMessages();
        this.formGroup.reset();
        this.itemDetail = { id: 0 };
        this.uploadedFiles = [];
        this.submitting = false;
    }
    scrollToTop() {
        if (this.formElement) {
            setTimeout(() => {
                this.formElement.nativeElement.scrollIntoView();
            }, 500);
        }
    }
    onInitEditor(event) {
        const commonService = this._injector.get(CommonService);
        commonService.addTabDisabledHandler(event);
    }
    isValid(submitting = true) {
        if (this.formGroup.invalid) {
            this.showValidateMessage();
            return;
        }
        return this.formGroup.valid;
    }
    delete() {
        this._notifierService.showDeleteConfirm().then(rs => {
            this._baseService.delete(this.itemDetail.id)
                .then(response => {
                this.closePopupMethod({});
                this._notifierService.showDeleteDataSuccess();
            }, error => {
                this._notifierService.showDeleteDataError();
            });
        });
    }
    togglePopupDelete() {
        this.openPopupDelete = !this.openPopupDelete;
    }
    keyEventN(event) {
        console.log(event);
        this.save();
    }
}
