var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Injector, OnInit } from '@angular/core';
import { SecondPageEditBase, UserService } from 'sv-shared';
import { FormBuilder, Validators } from '@angular/forms';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from '../../app.component';
import { CustomerUsersService } from '../../lasi/services/customer-users.service';
export class LoginComponent extends SecondPageEditBase {
    constructor(_injector, _router, formBuilder, _oauthService, authStorage, app, _userService, _lasiCustomerUsersService) {
        super(null, _injector);
        this._injector = _injector;
        this._router = _router;
        this.formBuilder = formBuilder;
        this._oauthService = _oauthService;
        this.authStorage = authStorage;
        this.app = app;
        this._userService = _userService;
        this._lasiCustomerUsersService = _lasiCustomerUsersService;
        this.model = new ModelDangNhap();
        this.error = false;
        this.enableLogin = true;
        this.formGroup = this.formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', Validators.required],
            remember: [''],
            email: ['']
        });
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.returnUrl = this._router.queryParams['returnUrl'] || '/';
            this._oauthService.requireHttps = false;
            if (this._authenticationService.isLoggedIn() && this._oauthService.hasValidAccessToken()) {
                top.location.href = this.returnUrl;
            }
            else {
                localStorage.clear();
                sessionStorage.clear();
            }
        });
    }
    clickLogin() {
        this.submitting = true;
        if (this.isValid) {
            this._oauthService.loadDiscoveryDocumentAndTryLogin().then(rs => {
                if (!rs) {
                    this._oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(this.model.username, this.model.password).then(result => {
                        this._lasiCustomerUsersService.checkAccountIsUse().then(rs => {
                            this._oauthService.setupAutomaticSilentRefresh();
                            // this.authorizeService.savePermission(result);
                            this._notifierService.showSuccess('Đăng nhập thành công, đang chuyển hướng!');
                            // this.app.getMenu();
                            setTimeout(() => {
                                this.submitting = false;
                                top.location.href = this.returnUrl;
                            }, 500);
                        }, error => {
                            // console.log(error.error.error_description);
                            this._notifierService.showWarning(error.error.error_description);
                            setTimeout(() => {
                                this.submitting = false;
                            }, 500);
                        });
                    }, error => {
                        // console.log(error.error.error_description);
                        this._notifierService.showWarning(error.error.error_description);
                        setTimeout(() => {
                            this.submitting = false;
                        }, 500);
                    });
                }
            });
        }
        else {
            console.log('invalid');
        }
    }
    onFocus() {
        this.submitting = false;
        this.error = false;
    }
    goForgotPass() {
        this.enableLogin = !this.enableLogin;
    }
    onResetPassword() {
        this._userService.resetPassword(this.model).then(rs => {
            if (rs.success) {
                this._notifierService.showSuccess("Mật khẩu mới đã dược gửi vào email của bạn");
            }
            else {
                this._notifierService.showWarning(rs.message);
            }
        }, error => {
            this._notifierService.showWarning(error.error.error);
        });
    }
}
class ModelDangNhap {
}
