import * as i0 from "@angular/core";
export class ListHelperService {
    constructor() {
    }
    getLimitByScreen() {
        const windowHeight = window.innerHeight;
        if (windowHeight > 707) {
            return 15;
        }
        if (windowHeight > 1920) {
            return 50;
        }
        return 10;
    }
}
ListHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ListHelperService_Factory() { return new ListHelperService(); }, token: ListHelperService, providedIn: "root" });
