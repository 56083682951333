import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class MeasurementValuesService extends BaseService {
    constructor(http, injector) {
        super(http, injector, `${environment.apiDomain.lasiEndPoint}/measurementvalues`);
    }
    find(searchModel) {
        const apiUrl = `${this.serviceUri}/Find`;
        return this._http
            .post(apiUrl, searchModel)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    importCsv(item) {
        const url = `${this.serviceUri}/ImportCsv`;
        return this._http.post(url, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    insertMany(item) {
        const url = `${this.serviceUri}/InsertMany`;
        return this._http.post(url, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    getMapViewByIdMeasurementStation(idMeasurementStation) {
        const apiUrl = `${this.serviceUri}/GetMapViewByIdMeasurementStation/${idMeasurementStation}`;
        return this.defaultGet(apiUrl);
    }
    getsForChartView(chartViewModel) {
        const apiUrl = `${this.serviceUri}/GetsForChartView`;
        return this._http
            .post(apiUrl, chartViewModel)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    baoCaoTongHopSoLieuCacTramDo(reportModel) {
        const apiUrl = `${this.serviceUri}/BaoCaoTongHopSoLieuCacTramDo`;
        return this._http
            .post(apiUrl, reportModel)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    baoCaoTongHopTheoTram(reportModel) {
        const apiUrl = `${this.serviceUri}/BaoCaoTongHopTheoTram`;
        return this._http
            .post(apiUrl, reportModel)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    baoCaoChiTietTheoTram(reportModel) {
        const apiUrl = `${this.serviceUri}/BaoCaoChiTietTheoTram`;
        return this._http
            .post(apiUrl, reportModel)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    baoCaoTongHopTheoYeuToDo(reportModel) {
        const apiUrl = `${this.serviceUri}/BaoCaoTongHopTheoYeuToDo`;
        return this._http
            .post(apiUrl, reportModel)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    baoCaoYeuToDoTrongNgay(reportModel) {
        const apiUrl = `${this.serviceUri}/BaoCaoYeuToDoTrongNgay`;
        return this._http
            .post(apiUrl, reportModel)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
MeasurementValuesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MeasurementValuesService_Factory() { return new MeasurementValuesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i0.INJECTOR)); }, token: MeasurementValuesService, providedIn: "root" });
