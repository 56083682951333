import { Component, Injector, OnInit } from '@angular/core';
import { SecondPageEditBase, SvUser, UserService } from 'sv-shared';
import { FormBuilder, Validators } from '@angular/forms';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { ActivatedRoute } from '@angular/router';
import { authConfig } from '../../config/oidc-auth.config';
import * as JWT from 'jwt-decode';
import { AppComponent } from '../../app.component';
import { CustomerUsersService } from '../../lasi/services/customer-users.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends SecondPageEditBase implements OnInit {
    model: ModelDangNhap = new ModelDangNhap();
    error = false;
    returnUrl: string;
    enableLogin = true;
    constructor(
        protected _injector: Injector,
        private _router: ActivatedRoute,
        private formBuilder: FormBuilder,
        private _oauthService: OAuthService,
        private authStorage: OAuthStorage,
        private app: AppComponent,
        private _userService: UserService,
        private _lasiCustomerUsersService: CustomerUsersService
    ) {
        super(null, _injector);
        this.formGroup = this.formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', Validators.required],
            remember: [''],
            email: ['']
        });
    }

    async ngOnInit() {
        this.returnUrl = this._router.queryParams['returnUrl'] || '/';
        this._oauthService.requireHttps = false;
        if (this._authenticationService.isLoggedIn() && this._oauthService.hasValidAccessToken()) {
            top.location.href = this.returnUrl;
        } else {
            localStorage.clear();
            sessionStorage.clear();
        }
    }

    clickLogin() {
        this.submitting = true;
        if (this.isValid) {
            this._oauthService.loadDiscoveryDocumentAndTryLogin().then(rs => {
                if (!rs) {
                    this._oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(this.model.username, this.model.password).then(result => {
                        this._lasiCustomerUsersService.checkAccountIsUse().then(rs => {
                            this._oauthService.setupAutomaticSilentRefresh();
                            // this.authorizeService.savePermission(result);
                            this._notifierService.showSuccess('Đăng nhập thành công, đang chuyển hướng!');
                            // this.app.getMenu();
                            setTimeout(() => {
                                this.submitting = false;
                                top.location.href = this.returnUrl;
                            }, 500);
                        },
                            error => {
                                // console.log(error.error.error_description);
                                this._notifierService.showWarning(error.error.error_description)
                                setTimeout(() => {
                                    this.submitting = false;
                                }, 500);
                            }
                        );


                    },
                        error => {
                            // console.log(error.error.error_description);
                            this._notifierService.showWarning(error.error.error_description)
                            setTimeout(() => {
                                this.submitting = false;
                            }, 500);
                        }
                    );
                }
            });

        } else {
            console.log('invalid');
        }

    }

    onFocus() {
        this.submitting = false;
        this.error = false;
    }
    goForgotPass() {
        this.enableLogin = !this.enableLogin;
    }

    onResetPassword() {
        this._userService.resetPassword(this.model).then(rs => {
            if (rs.success) {
                this._notifierService.showSuccess("Mật khẩu mới đã dược gửi vào email của bạn");
            } else {
                this._notifierService.showWarning(rs.message);
            }
        }, error => {
            this._notifierService.showWarning(error.error.error);
        });
    }
}


class ModelDangNhap {
    username: string;
    password: string;
    remember: boolean;
    email: string;
}