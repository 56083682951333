var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Injector } from '@angular/core';
import { SvUser } from '../models/sv-user';
import { OAuthService } from 'angular-oauth2-oidc';
import * as JWT from 'jwt-decode';
import { environment } from '../../../../../src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay, retry, catchError, map } from 'rxjs/operators';
import { ModuleConfigService } from './module-config.service';
import { BaseService } from './base.service';
import { ExceptionHandlerService } from './exception-handler.service';
export class UserService extends BaseService {
    // constructor(
    //     private _oauthService: OAuthService,
    //     private _http        : HttpClient,
    //     private _injector    : Injector
    // ) {
    // }
    constructor(http, injector, _moduleConfigService, _oauthService, _exceptionHandlerService) {
        super(http, injector, `${environment.apiDomain.coreEndPoint}/users`);
        this._oauthService = _oauthService;
        this._exceptionHandlerService = _exceptionHandlerService;
        this.USER_INFO_KEY = 'user_info';
        this.RETRY_COUNT = 0;
        this.REPLAY_COUNT = 10;
        this.DEFAULT_AVATAR_MALE = environment.appMetadata.main.defaultMaleAvatar;
        this.DEFAULT_AVATAR_FEMALE = environment.appMetadata.main.defaultFemaleAvatar;
        this.authenticationEnpoint = `${environment.apiDomain.coreEndPoint}`;
        this.storage = sessionStorage;
        this.tokenReceived = new Observable();
        this.promises = [];
        this.isReady = false;
    }
    returnPromises() {
        while (this.promises.length > 0) {
            const pr = this.promises.pop();
            const accessToken = this._oauthService.getAccessToken();
            const decoded = JWT(accessToken);
            const user = new SvUser();
            user.userId = decoded.sub;
            user.avatar = decoded.avatar;
            user.fullName = decoded.fullname;
        }
        this.isReady = true;
    }
    getForAutoComplete(key) {
        const apiUrl = `${this.serviceUri}/GetForAutoComplete?key=${key}`;
        return this._http.get(apiUrl)
            .pipe(catchError((err) => this._exceptionHandlerService.handleError(err, this._injector))).toPromise();
    }
    getCurrentUser() {
        return new Promise((resolve, reject) => {
            if (!this.isReady) {
                this.promises.push([resolve, reject]);
            }
            else {
                const key = 'AUTHORIZATION.USERINFO';
                const storageItem = this.storage.getItem(key);
                if (storageItem) {
                    const result = JSON.parse(storageItem);
                    resolve(result);
                }
                else {
                    const accessToken = this._oauthService.getAccessToken();
                    const decoded = JWT(accessToken);
                    const user = new SvUser();
                    user.issuperuser = decoded.issuperuser;
                    user.userId = decoded.userid;
                    user.avatar = decoded.avatar;
                    user.displayName = decoded.displayname;
                    this.storage.setItem(key, JSON.stringify(user));
                    resolve(user);
                    // this.getStaffInfo(decoded.sub).then(rs1 => {
                    //     const user = new SvUser();
                    //     if (rs1) {
                    //         user.issuperuser = decoded.issuperuser;
                    //         user.userId = decoded.sub;
                    //         user.avatar = rs1.avatar;
                    //         user.fullName = rs1.fullName;
                    //         user.position = rs1.position;
                    //         user.role = rs1.role;
                    //         if (!user.avatar || user.avatar === '') {
                    //             if (user.gender === 0) {
                    //                 user.avatar = this.DEFAULT_AVATAR_FEMALE;
                    //             } else {
                    //                 user.avatar = this.DEFAULT_AVATAR_MALE;
                    //             }
                    //         }
                    //         this.cachedUserInfo = user;
                    //     }
                    //     this.storage.setItem(key, JSON.stringify(user));
                    //     resolve(user);
                    // }, err => {
                    //     reject(err);
                    // });
                }
            }
        });
    }
    getId() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.cachedUserInfo) {
                return new Promise((resolve, reject) => {
                    resolve(this.cachedUserInfo.userId);
                });
            }
            else {
                return (yield this.getCurrentUser()).userId;
            }
        });
    }
    getBasicUserInfo(userId) {
        // const storageItem = localStorage.getItem(this.USER_INFO_KEY);
        // const userInfos   = <BasicUserInfo[]>JSON.parse(storageItem);
        // const user = userInfos ? userInfos.find(item => item.id === userId) : null;
        // if (user) {
        //     return new Promise((resolve, reject) => resolve(user));
        // } else {
        return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
            if (userId == null) {
                reject({});
            }
            else {
                const svUrl = `${this.serviceUri}/GetBasicUserInfo?id=${userId}`;
                const result = yield this._http.get(svUrl).pipe(shareReplay(this.REPLAY_COUNT), retry(this.RETRY_COUNT), map(repsonse => {
                    // save response to local storage here
                    localStorage.setItem(this.USER_INFO_KEY, JSON.stringify(repsonse.data));
                    return repsonse.data;
                }, catchError((err) => this.handleError(err, this._injector)))).toPromise();
                resolve(result);
            }
        }));
        // }
    }
    getBasicUsersInfo(userIds) {
        const storageItem = localStorage.getItem(this.USER_INFO_KEY);
        const userInfos = JSON.parse(storageItem);
        // const users = userInfos ? userInfos.filter(item => userIds.indexOf(`,${item.id},`) > -1) : null;
        const check = userInfos ? userIds.indexOf(userInfos['id']) > -1 : null;
        if (check) {
            return new Promise((resolve, reject) => resolve(userInfos));
        }
        else {
            return new Promise((resolve, reject) => {
                const svUrl = `${this.serviceUri}/GetBasicUsersInfo?userIds=${userIds}`;
                this._http.get(svUrl).pipe(shareReplay(this.REPLAY_COUNT), retry(this.RETRY_COUNT), catchError((err) => this.handleError(err, this._injector)), map(repsonse => repsonse.data)).toPromise().then(success => {
                    resolve(success);
                }).catch(err => {
                    reject(err);
                });
            });
        }
    }
    convertListUserIdToString(userIds) {
        if (userIds.length > 0) {
            return `,${userIds.join(',')},`;
        }
        return ',';
    }
    fillBasicUserInfo(dataSource, propertyName, users) {
        dataSource.forEach(item => {
            const userId = item[propertyName].id ? item[propertyName].id : item[propertyName];
            // tslint:disable-next-line:no-shadowed-variable
            const user = users.find(user => user.id === userId);
            if (user) {
                item[propertyName] = user;
            }
        });
        return dataSource;
    }
    restorePassword(item) {
        return this._http.put(`${this.serviceUri}/restorePassword`, item)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }
    getInSelectItem() {
        return new Promise((resolve, reject) => {
            this.get('')
                .then(userResponse => {
                const staffs = [];
                userResponse.data.forEach(element => {
                    staffs.push({ value: element.id, label: element.fullName });
                });
                resolve(staffs);
            }, error => {
                reject(error);
            });
        });
    }
    changePassword(item) {
        const apiUrl = `${this.serviceUri}/ChangePassword`;
        return this._http
            .post(apiUrl, item)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }
    GetsByManagerIdArrayId(id) {
        const apiUrl = `${this.serviceUri}/GetLowerStaffsByUserId?userId=${id}`;
        return this.defaultGet(apiUrl);
    }
    markAsAdmin(userId, isAdmin) {
        const url = `${this.serviceUri}/markAsAdmin/${userId}/${isAdmin}`;
        return this._http
            .put(url, isAdmin)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }
    markAsRepresent(userId, isRepresent) {
        const url = `${this.serviceUri}/markAsRepresent/${userId}/${isRepresent}`;
        return this._http
            .put(url, isRepresent)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }
    getDefaultAvatar() {
        return this.DEFAULT_AVATAR_MALE;
    }
    handleError(error, injector) {
        // console.error('Có lỗi xảy ra', error);
        if (error.status === 401) {
            // const authenService = injector.get(AuthenService);
            // authenService.logout();
            console.log('error 401');
        }
        else {
        }
        return Promise.reject(error);
    }
    find(keyword, trangThai, idOrganization, idPosition, pageIndex, pageSize, orderCol, isDesc = true) {
        let queryString = `?keyword=${keyword}`;
        if (keyword === undefined || keyword === null) {
            queryString = `?keyword=`;
        }
        queryString += `&trangThai=${trangThai}`;
        if (idOrganization > 0) {
            queryString += `&idOrganization=${idOrganization}`;
        }
        if (idPosition > 0) {
            queryString += `&idPosition=${idPosition}`;
        }
        if (!pageIndex) {
            pageIndex = 1;
        }
        queryString += `&pageIndex=${pageIndex}`;
        if (!pageSize) {
            pageSize = this.LIMIT_DEFAULT;
        }
        queryString += `&pageSize=${pageSize}`;
        if (orderCol) {
            queryString += `&orderCol=${orderCol}&isDesc=${isDesc}`;
        }
        const apiUrl = `${this.serviceUri}/find${queryString}`;
        return this.defaultGet(apiUrl);
    }
    logOut() {
        const apiUrl = `${this.serviceUri}/LogOut`;
        return this._http
            .post(apiUrl, true)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }
    resetPassword(model) {
        const apiUrl = `${this.serviceUri}Ex/resetPassword`;
        return this._http
            .post(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }
}
