import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../src/environments/environment';
import { BaseService } from './base.service';
import { ResponseResult } from '../models/response-result';

@Injectable({
    providedIn: 'root'
})
export class FsFilesService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.coreEndPoint}/fsfiles`);
    }

    getsByFolderId(idFsFolder: number, isGlobal: boolean, trangThai: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetsByFolderId?idFsFolder=${idFsFolder}&isGlobal=${isGlobal}&trangThai=${trangThai}`;
        return this.defaultGet(url);
    }
    getUploadUrl() {
        return `${this.serviceUri}/UploadFiles`;
    }

    getAnonymousDownloadUrl(instalceId: string) {
        return `${environment.apiDomain.coreEndPoint}/FsFilesDownload/${instalceId}`;
    }

    isSupportedViewOnline(fileName: string): boolean {
        // file không có đuổi -> loại
        if (fileName.lastIndexOf('.') === -1) {
            return false;
        }

        const fileType = fileName.substring(fileName.lastIndexOf('.') + 1);

        if ((/(gif|jpg|jpeg|tiff|png)$/i).test(fileType)) {
            return true;
        } else if ((/(doc|docm|docx|dot|dotm|dotx|epub|fodt|htm|html|mht|odt|ott|pdf|rtf|txt|djvu|xps)$/i).test(fileType)) {
            return true;
        } else if ((/(csv|fods|ods|ots|xls|xlsm|xlsx|xlt|xltm|xltx)$/i).test(fileType)) {
            return true;
        } else if ((/(fodp|odp|otp|pot|potm|potx|pps|ppsm|ppsx|ppt|pptm|pptx)$/i).test(fileType)) {
            return true;
        }

        return false;
    }
}
