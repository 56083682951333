import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class MeasurementStationsService extends BaseService {
    constructor(http, injector) {
        super(http, injector, `${environment.apiDomain.lasiEndPoint}/measurementstations`);
    }
    find(searchModel) {
        const apiUrl = `${this.serviceUri}/Find`;
        return this._http
            .post(apiUrl, searchModel)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
MeasurementStationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MeasurementStationsService_Factory() { return new MeasurementStationsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i0.INJECTOR)); }, token: MeasurementStationsService, providedIn: "root" });
