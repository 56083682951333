<div class="sv-page-content-area">
    <div class="sv-page-content-header-area">
        <div class="__page-title-area row">
            <div class="ui-g-12 ui-lg-6 __left-area">
            </div>
            <div class="ui-g-12 ui-lg-6 __right-area">
                <div class=" sv-search-box style-1 has-search-adv sv-custom-overlay-panel-wrapper">
                    <p-dropdown class="sv-dropdown" [filter]="true" [options]="arrTramDo" [(ngModel)]="idTramDo"
                        (onChange)="onChangeTramDo()">
                    </p-dropdown>
                </div>
            </div>
        </div>
    </div>
    <div class="sv-page-content-body-area">
        <p-scrollPanel [style]="{width: '100%', height: '100%'}">
            <div class="dashboard">
                <div class="ui-g row" style="margin-bottom: 10px;">
                    <div class="ui-g-12 ui-lg-8 chat">
                        <p-panel header="Mã trạm: {{itemTramDo.ma}} - Tên trạm: {{itemTramDo.ten}}">
                            <div class="row">Địa chỉ: {{itemTramDo.diaChi}}</div>
                            <div class="row">Tọa độ: <a
                                    href="/lasi/mstationsmap/{{itemTramDo.id}}">{{itemTramDo.gps}}</a> -
                                Cao độ (m):
                                {{itemTramDo.caoDo}}</div>
                            <div class="row">Trạng thái hoạt động: {{itemTramDo.tenTinhTrangHoatDong}}</div>
                        </p-panel>
                    </div>
                    <div class="ui-g-12 ui-lg-4" style="margin-top: 10px;" *ngIf="itemDienAp !== undefined">
                        <div class="row" style="text-align: center;">
                            <img *ngIf="itemDienAp.giaTri1 > 12" src="assets/images/iconlasi/3.png" />
                            <img *ngIf="itemDienAp.giaTri1 > 11.5 && itemDienAp.giaTri1 <= 12"
                                src="assets/images/iconlasi/2.png" />
                            <img *ngIf="itemDienAp.giaTri1 < 11.5" src="assets/images/iconlasi/1.png" />
                        </div>
                        <div class="row" style="text-align: center; font-size: 11px;">
                            <span style="padding-right: 42px;">0</span>{{itemDienAp.giaTri2}} - <span
                                style="font-weight: bold;">{{itemDienAp.giaTri1}}</span> <span
                                style="padding-left: 45px;">14</span>
                        </div>
                        <div class="row" style="text-align: center;">
                            {{itemDienAp.ma}} - {{itemDienAp.ten}} ({{itemDienAp.donViDo}})
                        </div>
                    </div>
                </div>
                <div class="ui-g row" style="margin-bottom: 10px;">
                    <div class="ui-g-12 ui-lg-6 chat valueBox" *ngFor="let item of arrSoLieuDo">
                        <p-panel>
                            <p-header>
                                <span style="font-weight: bold; font-size: 18px;" [style.color]="item.iconColor"
                                    class='{{item.icon}}'></span> &nbsp; &nbsp;
                                {{item.ma}}
                                -
                                {{item.ten}}
                                ({{item.donViDo}})
                            </p-header>
                            <table style="width: 100%">
                                <tr>
                                    <td style="width:50%; text-align: center;">
                                        <div class="row" style="font-size: 35px; font-weight: bold; padding: 10px;">
                                            {{item.giaTri2}}
                                        </div>
                                        <div class="row">{{item.strThoiGianDo2}}</div>
                                    </td>
                                    <td style="width:50%; text-align: center;">
                                        <div class="row"
                                            style="font-size: 35px; font-weight: bold; padding: 10px; color:forestgreen"
                                            *ngIf="item.giaTri1 >= item.giaTri2">
                                            {{item.giaTri1}}</div>
                                        <div class="row"
                                            style="font-size: 35px; font-weight: bold; padding: 10px; color:red;"
                                            *ngIf="item.giaTri1 < item.giaTri2">
                                            {{item.giaTri1}}
                                        </div>
                                        <div class="row">{{item.strThoiGianDo1}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:50%; text-align: center;">
                                        <div class="row">
                                            Trạng thái: {{item.tenTinhTrangHoatDongDuLieu1}}
                                        </div>
                                    </td>
                                    <td style="width:50%; text-align: center;">
                                        <div class="row">
                                            Trạng thái: {{item.tenTinhTrangHoatDongDuLieu2}}
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </p-panel>
                    </div>
                </div>
            </div>
        </p-scrollPanel>
    </div>
</div>