import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MeasurementValuesService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.lasiEndPoint}/measurementvalues`);
    }

    find(searchModel: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/Find`;
        return this._http
            .post<ResponseResult>(apiUrl, searchModel)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    importCsv(item: any): Promise<ResponseResult> {
        const url = `${this.serviceUri}/ImportCsv`;
        return this._http.post<ResponseResult>(url, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    insertMany(item: any): Promise<ResponseResult> {
        const url = `${this.serviceUri}/InsertMany`;
        return this._http.post<ResponseResult>(url, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getMapViewByIdMeasurementStation(idMeasurementStation: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetMapViewByIdMeasurementStation/${idMeasurementStation}`;
        return this.defaultGet(apiUrl);
    }

    getsForChartView(chartViewModel: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsForChartView`;
        return this._http
            .post<ResponseResult>(apiUrl, chartViewModel)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    baoCaoTongHopSoLieuCacTramDo(reportModel: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/BaoCaoTongHopSoLieuCacTramDo`;
        return this._http
            .post<ResponseResult>(apiUrl, reportModel)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    baoCaoTongHopTheoTram(reportModel: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/BaoCaoTongHopTheoTram`;
        return this._http
            .post<ResponseResult>(apiUrl, reportModel)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    baoCaoChiTietTheoTram(reportModel: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/BaoCaoChiTietTheoTram`;
        return this._http
            .post<ResponseResult>(apiUrl, reportModel)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    baoCaoTongHopTheoYeuToDo(reportModel: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/BaoCaoTongHopTheoYeuToDo`;
        return this._http
            .post<ResponseResult>(apiUrl, reportModel)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    baoCaoYeuToDoTrongNgay(reportModel: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/BaoCaoYeuToDoTrongNgay`;
        return this._http
            .post<ResponseResult>(apiUrl, reportModel)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
