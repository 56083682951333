var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { authConfig } from './config/oidc-auth.config';
import { UserService, CommonService, HtMenuService, AuthenticationService } from 'sv-shared';
import { ActivatedRoute, Router } from '@angular/router';
import { HeartBeatService } from './services/heart-beat.service';
import { environment } from '../environments/environment';
export class AppComponent {
    constructor(translate, _oauthService, auth, _userService, _activatedRoute, _router, _heartBeatService, _commonService, _menuService) {
        this.translate = translate;
        this._oauthService = _oauthService;
        this.auth = auth;
        this._userService = _userService;
        this._activatedRoute = _activatedRoute;
        this._router = _router;
        this._heartBeatService = _heartBeatService;
        this._commonService = _commonService;
        this._menuService = _menuService;
        this.pageLoaded = false;
        this.isMenuClicked = false;
        this.translate.setDefaultLang('vn');
        this.translate.use('vn');
        console.log(environment);
        // Config for authentication
        this.configureWithNewConfigApi();
    }
    ngAfterViewInit() {
        // setTimeout(() => { this.layoutMenuScrollerViewChild.moveBar(); }, 100);
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            // if (!environment.production) {
            //     // for dev evironment
            //     setInterval(() => {
            //         this._heartBeatService.sendHeartBeat();
            //     }, 70000);
            // }      
            // await this.getMenu();
        });
    }
    // async getMenu() {
    //     const cacheVal = sessionStorage.getItem('htMenu' + environment.clientDomain.idPhanhe.toString());
    //     if (cacheVal !== undefined && cacheVal !== null) {
    //         this.appMenuModel = await JSON.parse(cacheVal);
    //     } else {
    //         await this._menuService.getByIdPhanHe(environment.clientDomain.idPhanhe, 0).then(rs => {
    //             if (rs.success) {
    //                 this.appMenuModel = rs.data;
    //                 sessionStorage.setItem('htMenu' + environment.clientDomain.idPhanhe.toString(), JSON.stringify(rs.data));
    //             }
    //         });
    //     }
    // }
    configureWithNewConfigApi() {
        this._oauthService.setStorage(localStorage);
        this._oauthService.configure(authConfig);
        this._oauthService.tokenValidationHandler = new JwksValidationHandler();
        if (!this._oauthService.hasValidAccessToken()) {
            this._router.navigate(['/tai-khoan/dang-nhap']);
        }
        else {
            this._oauthService.tryLogin().then(rs => {
                this._oauthService.events.subscribe((event) => {
                    if (event.type === 'token_expires') {
                        this._oauthService.silentRefresh();
                    }
                });
            });
            this._userService.returnPromises();
            this.pageLoaded = true;
        }
    }
    onMenuCollapseToggleClick() {
        if (this.isDesktop()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        }
        else {
            this.staticMenuMobileActive = !this.staticMenuMobileActive;
        }
    }
    onSwitchModule(moduleCode) {
        switch (moduleCode) {
            case 0: { // administrator
                this._router.navigate(['/admin/master-data/single']);
                break;
            }
            default: { // frontend
                this._router.navigate(['/']);
                break;
            }
        }
    }
    onChangePassword(event) {
        // event.preventDefault();
        // this._userService.getId()
        //     .then(response => {
        //         this.changePassword.showPopup(response);
        //     });
    }
    onTopbarLogout() {
        // this._authenService.logout();
        this.auth.logout();
        this._router.navigate(['/tai-khoan/dang-nhap']);
    }
    hideOverlayMenu() {
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    }
    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }
    isDesktop() {
        return window.innerWidth > 1024;
    }
    isMobile() {
        return window.innerWidth <= 640;
    }
    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this._sub.unsubscribe();
    }
    newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // tslint:disable-next-line:no-bitwise
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    togglePageContentExpansionMode() {
        this.pageContentExpansionModeActive = !this.pageContentExpansionModeActive;
    }
}
