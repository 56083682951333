import { AuthenticationService } from '../services/authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/authentication.service";
export class SendAccessTokenInterceptor {
    constructor(authenService) {
        this.authenService = authenService;
    }
    intercept(request, next) {
        const logSessionKey = sessionStorage.getItem('log_session_key');
        if (logSessionKey) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.authenService.getAccessToken()}`,
                    'log-session': logSessionKey
                }
            });
        }
        else {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.authenService.getAccessToken()}`
                }
            });
        }
        return next.handle(request);
    }
}
SendAccessTokenInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SendAccessTokenInterceptor_Factory() { return new SendAccessTokenInterceptor(i0.ɵɵinject(i1.AuthenticationService)); }, token: SendAccessTokenInterceptor, providedIn: "root" });
