import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { FolderFormComponent } from '../folder-form/folder-form.component';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { TreeNode } from 'primeng/api';
import { SecondPageIndexBase } from '../../../classes/base/second-page-index-base';
import { FsFoldersService } from '../../../services/fs-folders.service';
import { FsFilesService } from '../../../services/fs-files.service';
import { FileViewerComponent } from '../file-viewer/file-viewer.component';

@Component({
    selector: 'app-file-explorer',
    templateUrl: './file-explorer.component.html',
    styleUrls: ['./file-explorer.component.scss']
})
export class FileExplorerComponent extends SecondPageIndexBase implements OnInit {
    rootTreeNode: TreeNode[] = [
        {
            'label': 'Menu',
            'data': '-1',
            'expandedIcon': 'fa-folder-open',
            'collapsedIcon': 'fa-folder',
            'children': [],
            'type': '1'
        }];
    openTreeSearchAdv: boolean;
    openLeftTableSidebar = true;
    selectedTreeNode: TreeNode;
    openLeftSidebar = true;
    itemFolder = { id: 0, idFsFolderCha: 0, ten: '' };
    isGlobal = false;
    dsPhanLoai = [{ label: 'Cá nhân', value: false }, { label: 'Chung', value: true }];
    breadcrumbItems: any[];
    @ViewChild('pEdit', { static: false }) pEdit: FolderFormComponent;
    @ViewChild('pUpload', { static: false }) pUpload: FileUploadComponent;
    @ViewChild('pFileViewer', { static: false }) pFileViewer: FileViewerComponent;

    currentFileViewerName = '';
    currentFileViewerUrl = '';

    constructor(
        protected _FsFoldersService: FsFoldersService,
        protected _FsFilesService: FsFilesService,
        protected _injector: Injector,
    ) {
        super(null, _injector);
    }

    ngOnInit() {
        this.cols = [
            { field: 'ten', header: this._translateService.instant('Tên'), visible: true, width: 'auto', },
            { field: 'totalSize', header: this._translateService.instant('Dung lượng'), visible: true, width: 'auto', },
            { field: 'modified', header: this._translateService.instant('TABLEDATA.MODIFIED'), visible: true, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'modifiedByName', header: this._translateService.instant('TABLEDATA.MODIFIED_BY'), visible: true, width: 'auto' },
        ];
        this.getData();
        this.loadBreadcrumbItems();
    }

    editFolder() {
        const item = this.selectedItems[0];
        this.pEdit.showPopup({ id: item.id, parentFolderId: this.itemFolder.id, isGlobal: this.isGlobal });
    }

    addFolder() {
        this.pEdit.showPopup({ id: 0, parentFolderId: this.itemFolder.id, isGlobal: this.isGlobal });
    }

    edit() {
        const item = this.selectedItems[0];
        if (item.type == 2) {
            this.editFolder();
        }
    }
    delete() {
        const item = this.selectedItems[0];
        this._notifierService.showDeleteConfirm().then(rs => {
            if (item.type == 2) {
                this._FsFoldersService.delete(item.id)
                    .then(response => {
                        this._notifierService.showDeleteDataSuccess();
                        this.getData();
                    }, error => {
                        this._notifierService.showDeleteDataError();
                    });
            }
            else {
                this._FsFilesService.delete(item.id)
                    .then(response => {
                        this._notifierService.showDeleteDataSuccess();
                        this.getData();
                    }, error => {
                        this._notifierService.showDeleteDataError();
                    });
            }

        });
    }
    async getData() {

        // GetTree
        this._FsFoldersService.getTree(this.isGlobal, this.trangThai).then(rs => {
            if (rs.success) {
                this.rootTreeNode = rs.data;
                this.rootTreeNode.forEach(node => {
                    this.expandRecursive(node, true);
                });
            }
        });
        this.getDataTable();
    }

    async getDataTable() {
        this.resetBulkSelect();
        this.loadBreadcrumbItems();
        // DataTable
        this.dataSource = [];
        if (this.itemFolder.id > 0) {
            if (this.itemFolder.idFsFolderCha > 0) {
                await this._FsFoldersService.getDetail(this.itemFolder.idFsFolderCha).then(rs => {
                    if (rs.success) {
                        this.dataSource.push({
                            id: rs.data.id,
                            idFsFolderCha: rs.data.idFsFolderCha,
                            trangThai: 1,
                            ten: '...',
                            type: 2,
                            instanceId: '',
                        });
                    }
                })
            }
            else {
                this.dataSource.push({
                    id: 0,
                    idFsFolderCha: 0,
                    trangThai: 1,
                    ten: '...',
                    type: 2,
                });
            }
        }

        await this._FsFoldersService.getsChildFolder(this.itemFolder.id, this.isGlobal, this.trangThai).then(rsFo => {
            if (rsFo.success) {
                rsFo.data.forEach(element => {
                    var foItem = {
                        id: element.id,
                        ten: element.ten,
                        totalSize: element.totalSize,
                        type: 2,
                        trangThai: element.trangThai,
                        idFsFolderCha: element.idFsFolderCha,
                        modified: element.modified,
                        modifiedByName: element.modifiedByName,
                        instanceId: element.instanceId,
                    };
                    this.dataSource.push(foItem);
                });
            }
        });

        await this._FsFilesService.getsByFolderId(this.itemFolder.id, this.isGlobal, this.trangThai).then(rsFi => {
            if (rsFi.success) {
                rsFi.data.forEach(element => {
                    var fiItem = {
                        id: element.id,
                        ten: element.ten,
                        totalSize: element.totalSize,
                        type: 1,
                        trangThai: element.trangThai,
                        modified: element.modified,
                        modifiedByName: element.modifiedByName,
                        instanceId: element.instanceId,
                    };
                    this.dataSource.push(fiItem);
                });
            }
        })
    }

    async onItemClick(item: any) {
        if (item.type === 2) {
            this.itemFolder = item;
            await this.checkNode(item.id);
            await this.getDataTable();

        } else {
            // if (this._fileService.canBeOpenOnline(item)) {
            //     window.open(this._fileService.getLinkView(item.id), '_blank');
            // }
        }
    }

    uploadFiles() {
        this.pUpload.showPopup({ id: this.itemFolder.id, isGlobal: this.isGlobal });
    }

    //#region Tree
    toggleLeftSidebar() {
        this.openLeftSidebar = !this.openLeftSidebar;
    }


    toggleTreeSearchAdv() {
        this.openTreeSearchAdv = !this.openTreeSearchAdv;
    }
    toggleLeftTableSidebar() {
        this.openLeftTableSidebar = !this.openLeftTableSidebar;
    }
    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach(childNode => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }
    nodeSelect(event) {
        this.itemFolder = {
            id: this.selectedTreeNode.data,
            idFsFolderCha: this.selectedTreeNode.parent !== undefined ? this.selectedTreeNode.parent.data : 0,
            ten: this.selectedTreeNode.label
        };
        this.getDataTable();
    }
    //#endregion

    onSearch(): void {
        this.itemFolder = { id: 0, idFsFolderCha: 0, ten: '' };
        this.getData();
    }

    loadBreadcrumbItems() {
        this.breadcrumbItems = [];
        var tempNode = this.selectedTreeNode;

        while (tempNode !== undefined) {
            this.breadcrumbItems.unshift({ label: tempNode.label, href: 'javascript:void(0)' });
            tempNode = tempNode.parent;
        }
    }

    checkNode(folderId) {
        this.selectedTreeNode = undefined;
        this.rootTreeNode.forEach(element => {
            this.treeFilter(element, folderId);
        });
    }

    treeFilter(node: TreeNode, folderId) {
        if (this.selectedTreeNode !== undefined && this.selectedTreeNode.data === folderId) {
            return;
        }
        if (node.data === folderId) {
            this.selectedTreeNode = node;
            return;
        }
        if (node.children) {
            node.children.forEach(childNode => {
                this.treeFilter(childNode, folderId);
            });
        }
    }

    async viewFile() {
        const item = this.selectedItems[0];
        if (item.type === 2) {
            // Mở thư mục con
            this.onItemClick(item);
        } else {
            // Xem file online (nếu được hỗ trợ)
            if (this._FsFilesService.isSupportedViewOnline(item.ten)) {
                this.currentFileViewerName = item.ten;
                var fileUrl = this._FsFilesService.getAnonymousDownloadUrl(item.instanceId);
                this.pFileViewer.showPopup(true, { fileName: item.ten, fileUrl: fileUrl });
            } else {
                // download file
                top.location.href = await this._FsFilesService.getAnonymousDownloadUrl(item.instanceId);;
            }
        }
    }

}
