
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
            <li [ngClass]="[
                isActive(i, child) ? 'activated' : '',
                isNumber(level) ? 'menu-item-level-' + level : '',
                child.items && (!child.routerLink || child.routerLink === '#') ? 'has-submenu' : '',
                child.id ? 'menu-id-' + child.id : ''
            ]"
                [authorize]="child.phanQuyen"
                *ngIf="child.visible !== false" [hidden]="child.trangThai !== 1">

                <a (click)="itemClick($event,child,i,level)" class="__ripple-link"
                   *ngIf="!child.routerLink || child.routerLink === '#'"
                   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                    <i class="__main-icon" [ngClass]="child.icon" *ngIf="level === 1"></i>

                    <span class="__label">{{child.label}}</span>

                    <i class="ci ci-chevron-right-1 __toggle-icon" *ngIf="level > 0"></i>
                </a>

                <a (click)="itemClick($event,child,i,level)" class="__ripple-link"
                   *ngIf="child.routerLink && child.routerLink !== '#'"
                   [routerLink]="child.routerLink"
                   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                    <i class="__main-icon" [ngClass]="child.icon" *ngIf="level === 1"></i>

                    <span class="__label">{{child.label}}</span>

                    <i class="ci ci-chevron-right-1 __toggle-icon" *ngIf="level > 0"></i>
                </a>
                
                <ul app-submenu [item]="child" [level]="level + 1" *ngIf="child.items && level < 3"
                    [ngClass]="[
                        'submenu-level-' + (level + 1)
                    ]"
                    [hidden]="child.routerLink && child.routerLink !== '#'"
                    [visible]="isActive(i, child)" [reset]="reset" 
                    [parentActive]="isActive(i, child)"
                    [groupIndex]="root ? i : groupIndex"
                    [@children]="level > 0 ? (isActive(i, child) ? 'visibleAnimated' : 'hiddenAnimated') : 'visible'">
                </ul>
            </li>
        </ng-template>
    