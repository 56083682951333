import { ModuleConfig } from 'sv-shared';
import { environment } from '../../environments/environment';

export const SV_COMPONENT_CONFIG = <ModuleConfig>{
    Services: {
        Gateway: environment.apiDomain.gateway,
        // AuthenticationEndpoint: environment.apiDomain.authenauthorEnpoint,
        // AuthorizationEndPoint: environment.apiDomain.authenauthorEnpoint
        CoreEndPoint: environment.apiDomain.coreEndPoint,
    },
    Assets: {
        LogoUrl: environment.appMetadata['main'].logo
    },
};
