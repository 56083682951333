import { Component, OnInit, ViewChild, ElementRef, Injector } from '@angular/core';
import { UserService, NotifierService, PermissionService, SecondPageIndexBase } from 'sv-shared';
import { Router, Route } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MeasurementStationsService } from '../lasi/services/measurement-stations.service';
import { MeasurementValuesService } from '../lasi/services/measurement-values.service';
import { map, takeUntil } from 'rxjs/operators';


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends SecondPageIndexBase implements OnInit {
    cols = [];
    itemTramDo = { id: 0, ma: '', ten: '', tenTinhTrangHoatDong: 0, diaChi: '', caoDo: '', gps: '' };
    itemTinhTrangHoatDong = { label: '', value: 0 };
    arrSoLieuDo = [];
    arrTramDo = [];
    idTramDo = 0;
    itemDienAp: { ma: '', ten: '', donViDo: '', giaTri1: 0, giaTri2: 0, };
    constructor(
        protected _permissionService: PermissionService,
        protected _measurementstationsService: MeasurementStationsService,
        protected _measurementValuesService: MeasurementValuesService,
        protected _injector: Injector,
    ) {
        super(_measurementstationsService, _injector);
    }
    async ngOnInit() {

        this.cols = [
            { field: 'ten', header: this._translateService.instant('Tên'), visible: true, sort: true, width: 'auto', },
            { field: 'tenTinhTrangHoatDong', header: this._translateService.instant('Hoạt động'), visible: true, width: 'auto', },
            { field: 'mucDienAp', header: this._translateService.instant('Mức điện áp'), visible: true, width: 'auto', },
            { field: 'gps', header: this._translateService.instant('Tọa độ GPS'), visible: true, width: 'auto', },
        ];
        this.pageSize = 5;
        await this.getData();
        await this._activatedRoute.params
            .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
            .subscribe(pid => {
                if (pid !== undefined) {
                    this.idTramDo = pid;
                }
            });
        this.getSoLieuDoGanNhat();
    }

    async getData() {
        this.isLoading = true;
        var isLaSiAdmin = false;
        await this._permissionService.isLaSiAdmin().then(rs => {
            isLaSiAdmin = rs;
        })
        var searchModel = {
            keyword: '',
            trangThai: 1,
            idCategory: 0,
            idCustomer: 0,
            tinhTrangHoatDong: 0,
            isLaSiAdmin: isLaSiAdmin,
            pageIndex: this.pageIndex,
            pageSize: 9999,
            orderCol: this.orderCol ? this.orderCol : 'Id',
            isDesc: this.isDesc,
        };
        this.arrTramDo = [];
        await this._measurementstationsService.find(searchModel)
            .then(response => {
                this.dataSource = response.data;
                this.convertDataToOptions(this.arrTramDo, response.data, 'ten');
                this.totalRecord = response.totalRecord;
                this.idTramDo = this.dataSource[0].id
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });
    }

    getSoLieuDoGanNhat() {
        this.itemTramDo = this.dataSource.filter(x => Number(x.id) === Number(this.idTramDo))[0];
        this.arrSoLieuDo = [];
        if (this.idTramDo > 0) {
            this._measurementValuesService.getMapViewByIdMeasurementStation(this.idTramDo).then(rs => {
                if (rs.success) {
                    this.arrSoLieuDo = rs.data.filter(x => x.ma !== '0800');
                    this.itemDienAp = rs.data.filter(x => x.ma === '0800')[0];
                    this.itemTramDo.tenTinhTrangHoatDong = rs.data[0].tenTinhTrangHoatDongDuLieuTram;
                }
            });
        }
    }
    onChangeTramDo() {
        this.getSoLieuDoGanNhat();
    }
}

