var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, Injector } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FsFoldersService } from '../../../services/fs-folders.service';
import { SecondPageEditBase } from '../../../classes/base/second-page-edit-base';
export class FolderFormComponent extends SecondPageEditBase {
    constructor(_FsFoldersService, _injector) {
        super(_FsFoldersService, _injector);
        this._FsFoldersService = _FsFoldersService;
        this._injector = _injector;
        this.parentFolderId = 0;
        this.isGlobal = false;
        this.formGroup = new FormGroup({
            ten: new FormControl('', Validators.compose([Validators.required])),
            trangThai: new FormControl('')
        });
    }
    ngOnInit() {
        this.invalid = this.formGroup.invalid;
    }
    onShowPopup(data) {
        return __awaiter(this, void 0, void 0, function* () {
            this.parentFolderId = data.parentFolderId;
            this.isGlobal = data.isGlobal;
            this.submitting = true;
            this.validationSummary.resetErrorMessages();
            if (data.id > 0) {
                this.popupHeader = 'Sửa thông tin ';
                this._FsFoldersService.getDetail(data.id).then(rs => {
                    this.submitting = false;
                    if (rs.success) {
                        this.itemDetail = rs.data;
                    }
                }, error => {
                    this._notifierService.showWarning('Không tìm thấy bản ghi được yêu cầu');
                    this.submitting = false;
                });
            }
            else {
                this.itemDetail = { id: 0 };
                this.submitting = false;
            }
        });
    }
    onInsert() {
        this._FsFoldersService.createFolder(this.parentFolderId, this.isGlobal, this.itemDetail.ten)
            .then(response => {
            if (this.continueAdding) {
                this.resetForm();
            }
            else {
                this.closePopupMethod(true);
            }
            this._notifierService.showInsertDataSuccess();
            this.onAfterSave();
            this.submitting = false;
        }, error => {
            this._notifierService.showInsertDataFailed();
            this.submitting = false;
        });
    }
}
