var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ElementRef, OnInit, AfterContentChecked } from '@angular/core';
import { PermissionTypes } from '../classes/constants';
import { PermissionService } from '../services/permission.service';
import { UserService } from '../services/user.service';
export class AuthorizeDirective {
    constructor(_el, _permissionService, _userService) {
        this._el = _el;
        this._permissionService = _permissionService;
        this._userService = _userService;
        this._permissionTypes = PermissionTypes.CONTROL;
        this.isNavigationPermission = false;
        this._element = _el.nativeElement;
    }
    set authorize(value) {
        this._permissionRequired = value;
    }
    set permissionType(value) {
        this._permissionTypes = value;
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            const currentUser = yield this._userService.getCurrentUser();
            if (currentUser.issuperuser === 'True') {
                return;
            }
            if (this._permissionRequired) {
                let isAuthorized = false;
                try {
                    this._permissionRequired = JSON.parse(this._permissionRequired.toString().replace(/'/g, '"'));
                    for (const i in this._permissionRequired) {
                        const key = Object.keys(this._permissionRequired[i])[0];
                        const value = this._permissionRequired[i][key];
                        isAuthorized = yield this._permissionService.isAuthorized(key, value);
                        if (isAuthorized) {
                            break;
                        }
                    }
                }
                catch (_a) {
                }
                if (!isAuthorized) {
                    if (this.keepHTML) {
                        this._label = document.createElement('span');
                        if (this._element.parentElement) {
                            this._element.parentElement.appendChild(this._label);
                            this._element.remove();
                        }
                    }
                    else {
                        this._element.remove();
                    }
                }
            }
            const isAuthorized = yield this._permissionService.isAuthorized(this._permissionRequired, this.permission);
            if (!isAuthorized) {
                if (this.keepHTML) {
                    this._label = document.createElement('span');
                    if (this._element.parentElement) {
                        this._element.parentElement.appendChild(this._label);
                        this._element.remove();
                    }
                }
                else {
                    this._element.remove();
                }
            }
        });
    }
    ngAfterContentChecked() {
        if (this._label && !this._bind && this._element.innerHTML !== '' && this.keepHTML) {
            this._label.innerHTML = this._element.innerHTML;
            this._bind = true;
        }
    }
}
