import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../src/environments/environment';
import { BaseService } from './base.service';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class NotificationService extends BaseService {
    constructor(http, injector) {
        super(http, injector, `${environment.apiDomain.coreEndPoint}/NotificationTo`);
    }
    getsToUserId(userId, isRead, pageIndex, pageSize) {
        const apiUrl = `${this.serviceUri}/getsToUserId?userId=${userId}&isRead=${isRead}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
        return this.defaultGet(apiUrl);
    }
    readAll() {
        const apiUrl = `${this.serviceUri}/ReadAll`;
        return this._http
            .post(apiUrl, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    readMessage(id) {
        const apiUrl = `${this.serviceUri}/ReadMessage/${id}`;
        return this._http
            .post(apiUrl, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i0.INJECTOR)); }, token: NotificationService, providedIn: "root" });
