import * as i0 from "@angular/core";
export class SvCommonService {
    constructor() {
    }
    refreshLogSession() {
        const newKey = this.genGuid();
        sessionStorage.setItem('log_session_key', newKey);
    }
    genGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}
SvCommonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SvCommonService_Factory() { return new SvCommonService(); }, token: SvCommonService, providedIn: "root" });
