import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'sv-shared';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CustomerUsersService extends BaseService {
    constructor(http, injector) {
        super(http, injector, `${environment.apiDomain.lasiEndPoint}/customerusers`);
    }
    getsByIdCustomer(idCustomer) {
        const apiUrl = `${this.serviceUri}/GetsByIdCustomer/${idCustomer}`;
        return this.defaultGet(apiUrl);
    }
    checkAccountIsUse() {
        const apiUrl = `${this.serviceUri}/CheckAccountIsUse`;
        return this.defaultGet(apiUrl);
    }
}
CustomerUsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomerUsersService_Factory() { return new CustomerUsersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i0.INJECTOR)); }, token: CustomerUsersService, providedIn: "root" });
