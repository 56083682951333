var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PipeTransform } from '@angular/core';
import { UserService } from '../services/user.service';
export class UserFormatPipe {
    constructor(_userService) {
        this._userService = _userService;
    }
    transform(value, args0, args1) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                let result = '';
                if (value == null || !value || value < 0) {
                    resolve(result);
                }
                const userInfo = yield this._userService.getBasicUserInfo(value);
                if (!args0 && !args1) {
                    // Gia tri mac dinh, neu nhu khong truyen gi vao
                    result = `${this.getUserInfo(userInfo, 'firstName')} ${this.getUserInfo(userInfo, 'lastName')}`;
                }
                else {
                    if (args0) {
                        result = this.getUserInfo(userInfo, args0.valueOf());
                    }
                    if (args1) {
                        result += this.getUserInfo(userInfo, args1.valueOf());
                    }
                }
                resolve(result);
            }));
        });
    }
    getUserInfo(userInfo, format) {
        if (!userInfo)
            return '';
        switch (format) {
            case 'NAME': {
                if (userInfo.firstName || userInfo.lastName) {
                    return `${userInfo.firstName} ${userInfo.lastName}`;
                }
                else {
                    return userInfo.fullName;
                }
            }
            case 'AVATAR': {
                if (userInfo.avatar) {
                    // Need format html
                    return userInfo.avatar;
                }
                return '';
            }
            default: {
                return userInfo[format];
            }
        }
    }
}
