import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { environment } from '../../../../../src/environments/environment';
import { CommonService } from './common.service';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./common.service";
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const apiEndpoint = environment.apiDomain.coreEndPoint + '/Export';
export class ExportService {
    constructor(http, _commonService, _injector) {
        this.http = http;
        this._commonService = _commonService;
        this._injector = _injector;
        this._http = http;
    }
    saveAsExcelFile(buffer, fileName) {
        const data = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
    }
    export(data, cols, fileName) {
        const obj = {
            data: data,
            cols: cols
        };
        return this._http
            .post(`${apiEndpoint}/export`, obj, { responseType: 'blob' })
            .pipe(catchError((err) => this._commonService.handleError(err, this._injector)))
            .subscribe(res => {
            this.saveAsExcelFile(res, fileName);
        });
    }
}
ExportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExportService_Factory() { return new ExportService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CommonService), i0.ɵɵinject(i0.INJECTOR)); }, token: ExportService, providedIn: "root" });
