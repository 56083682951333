<div class="sv-page-container" [ngClass]="{
                'expansion-mode-active': app.pageContentExpansionModeActive,
                'expansion-mode-inactive': !app.pageContentExpansionModeActive,
                'menu-collapsed-in-desktop': app.staticMenuDesktopInactive,
                'menu-expanded-in-desktop': !app.staticMenuDesktopInactive,
                'menu-collapsed-in-small-device': !app.staticMenuMobileActive,
                'menu-expanded-in-small-device': app.staticMenuMobileActive
                }">
    <app-topbar></app-topbar>

    <div class="sv-page-body-area">
        <div class="sv-left-menu-area">
            <app-menu-search-box></app-menu-search-box>

            <div class="sv-main-menu-wrapper">
                <p-scrollPanel [style]="{width: '100%', height: '100%'}">
                    <app-menu></app-menu>
                </p-scrollPanel>
            </div>
        </div>

        <div class="sv-page-content-area-wrapper">
            <router-outlet></router-outlet>
        </div>
    </div>

    <div class="sv-page-overlay"></div>
</div>

<p-toast class="sv-toast" position="bottom-right" key="success-warn"></p-toast>

<p-toast class="sv-toast" [style]="{marginTop: '80px'}" position="top-right" key="error"></p-toast>

<p-confirmDialog [autoZIndex]="true" appendTo="body"></p-confirmDialog>