<form [formGroup]="formGroup">

    <div class="sv-form-control type-text-field required"
        [ngClass]="{'error': (formGroup.get('username').errors?.required && submitting)}">
        <div class="__control-label">Tên đăng nhập</div>

        <div class="__control-input-wrapper">
            <input class="__control-input" type="text" (focus)="onFocus()" [(ngModel)]="model.username"
                formControlName="username" placeholder="{{'Nhập tên đăng nhập' | translate}}">
        </div>

        <div class="__control-messages">
            <div class="__message-item">Trường thông tin bắt buộc</div>
        </div>
    </div>
    <div *ngIf="enableLogin">
        <div class="sv-form-control type-text-field required"
            [ngClass]="{'error': (formGroup.get('password').errors?.required && submitting) || error}">
            <div class="__control-label">Mật khẩu</div>

            <div class="__control-input-wrapper">
                <input class="__control-input" type="password" (focus)="onFocus()" [(ngModel)]="model.password"
                    (keyup.enter)="clickLogin()" formControlName="password"
                    placeholder="{{'Nhập mật khẩu' | translate}}">
            </div>

            <div class="__control-messages" *ngIf="!error">
                <div class="__message-item">Trường thông tin bắt buộc</div>
            </div>

            <div class="__control-messages" *ngIf="error">
                <div class="__message-item">Thông tin đăng nhập không chính xác</div>
            </div>
        </div>

        <div class="__remember-password">
            <p-checkbox [(ngModel)]="model.remember" formControlName="remember"
                label="{{'Ghi nhớ đăng nhập' | translate}}" class="tn-checkbox"></p-checkbox>

            <div class="__forgot-password cursor-pointer" (click)="goForgotPass()">{{'Quên mật khẩu?' | translate}}
            </div>
        </div>

        <button type="button" (click)="clickLogin()"
            class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active easing-fast">
            <span class="__label">{{'Đăng nhập' | translate}}</span>
            <span class="__icon ci ci-arrow-right"></span>
        </button>

    </div>
    <div *ngIf="!enableLogin">
        <div class="sv-form-control type-text-field required"
            [ngClass]="{'error': (formGroup.get('email').errors?.required && submitting)}">
            <div class="__control-label">Email liên hệ</div>

            <div class="__control-input-wrapper">
                <input class="__control-input" type="text" (focus)="onFocus()" [(ngModel)]="model.email"
                    formControlName="email" placeholder="{{'Nhập email liên hệ' | translate}}">
            </div>

            <div class="__control-messages">
                <div class="__message-item">Trường thông tin bắt buộc</div>
            </div>
        </div>

        <div class="__remember-password">
            <div class="__forgot-password cursor-pointer" (click)="goForgotPass()">{{'Trở về' | translate}}
            </div>
        </div>

        <button type="button" (click)="onResetPassword()"
            class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active easing-fast">
            <span class="__label">{{'Quên mật khẩu' | translate}}</span>
            <span class="__icon ci ci-arrow-right"></span>
        </button>
    </div>

</form>