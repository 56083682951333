import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../src/environments/environment';
import { BaseService } from './base.service';
import { ResponseResult } from '../models/response-result';

@Injectable({
    providedIn: 'root'
})
export class FsFoldersService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.coreEndPoint}/fsfolders`);
    }

    getsChildFolder(idFsFolderCha: number, isGlobal: boolean, trangThai: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetsChildFolder?idFsFolderCha=${idFsFolderCha}&isGlobal=${isGlobal}&trangThai=${trangThai}`;
        return this.defaultGet(url);
    }

    createFolder(parentFolderId: number, isGlobal: boolean, ten: string): Promise<ResponseResult> {
        const url = `${this.serviceUri}/CreateFolder?parentFolderId=${parentFolderId}&isGlobal=${isGlobal}&ten=${ten}`;
        return this._http
            .post<ResponseResult>(url, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getTree(isGlobal: boolean, trangThai: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetTree?isGlobal=${isGlobal}&trangThai=${trangThai}`;
        return this.defaultGet(url);
    }
}
