var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Injector, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';
import { HtMenuService, NotifierService } from 'sv-shared';
import { Subject } from 'rxjs/internal/Subject';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
export class AppMenuComponent {
    constructor(app, _injector) {
        this.app = app;
        this._injector = _injector;
        this._unsubscribeAll = new Subject();
        this._notifierService = this._injector.get(NotifierService);
        this._translateService = this._injector.get(TranslateService);
        this._menuService = this._injector.get(HtMenuService);
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            // this.model = this.app.appMenuModel;
            yield this.getMenu();
        });
    }
    getMenu() {
        return __awaiter(this, void 0, void 0, function* () {
            const cacheVal = sessionStorage.getItem('htMenu' + environment.clientDomain.idPhanhe.toString());
            if (cacheVal !== undefined && cacheVal !== null) {
                this.model = yield JSON.parse(cacheVal);
            }
            else {
                yield this._menuService.getByIdPhanHe(environment.clientDomain.idPhanhe, 0).then(rs => {
                    if (rs.success) {
                        this.model = rs.data;
                        sessionStorage.setItem('htMenu' + environment.clientDomain.idPhanhe.toString(), JSON.stringify(rs.data));
                    }
                });
            }
        });
    }
}
export class AppSubMenuComponent {
    constructor(app, _router) {
        this.app = app;
        this._router = _router;
        this.isFirstLoad = true;
    }
    itemClick(event, item, index, level) {
        if (level === 0) {
            return;
        }
        this.isFirstLoad = false;
        this.app.isMenuClicked = true;
        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }
        // activate current item and deactivate active sibling if any
        if (this.app.activatedMenuGroupIndex !== this.groupIndex) {
            this.activeIndex = index;
        }
        else {
            this.activeIndex = (this.activeIndex === index) ? null : index;
        }
        this.app.activatedMenuGroupIndex = this.groupIndex;
        // execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }
        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            // setTimeout(() => {
            //     this.app.layoutMenuScrollerViewChild.moveBar();
            // }, 450);
            event.preventDefault();
        }
        // hide menu
        if (!item.items) {
            this.app.overlayMenuActive = false;
            this.app.staticMenuMobileActive = false;
        }
        if (item.url || item.routerLink) {
            this.activeIndex = null;
            this.isFirstLoad = true;
        }
    }
    isActive(index, currentItem) {
        if (this.app.isMenuClicked && this.app.activatedMenuGroupIndex !== this.groupIndex) {
            return false;
        }
        if (!this.isFirstLoad) {
            return this.activeIndex == index;
        }
        if (currentItem && currentItem.routerLink) {
            if (this.isSameWithCurrentPageURL(currentItem.routerLink)) {
                this.activeIndex = index;
                return true;
            }
        }
        if (currentItem && currentItem.items) {
            for (let i in currentItem.items) {
                let childItem = currentItem.items[i];
                if (this.isActive(index, childItem)) {
                    return true;
                }
            }
        }
        return false;
    }
    isSameWithCurrentPageURL(routerLink) {
        const currentUrl = this._router.url;
        if (routerLink === currentUrl) {
            return true;
        }
        let currentUrlWithoutParam = currentUrl;
        if (currentUrlWithoutParam.includes('?')) {
            currentUrlWithoutParam = currentUrlWithoutParam.substr(0, currentUrlWithoutParam.indexOf('?'));
        }
        currentUrlWithoutParam = currentUrlWithoutParam.replace(/\d+/g, (str) => '--');
        return currentUrlWithoutParam === routerLink;
    }
    isNumber(element) {
        return !isNaN(element);
    }
    get reset() {
        return this._reset;
    }
    set reset(val) {
        this._reset = val;
    }
    get parentActive() {
        return this._parentActive;
    }
    set parentActive(val) {
        this._parentActive = val;
        if (!this._parentActive) {
            this.activeIndex = null;
        }
    }
}
