import { Component, AfterViewInit, Renderer2, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { ScrollPanel } from 'primeng/primeng';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService, JwksValidationHandler, OAuthInfoEvent } from 'angular-oauth2-oidc';
import { authConfig } from './config/oidc-auth.config';
import { Subject } from 'rxjs/internal/Subject';
import { UserService, CommonService, HtMenuService, AuthenticationService } from 'sv-shared';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { HeartBeatService } from './services/heart-beat.service';
import { environment } from '../environments/environment';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy, OnInit {

    // Private
    private _unsubscribeAll: Subject<any>;
    private _sub: Subscription;

    overlayMenuActive: boolean;

    staticMenuDesktopInactive: boolean;

    staticMenuMobileActive: boolean;

    // chế độ mở rộng trang
    pageContentExpansionModeActive: boolean;

    pageLoaded = false;

    // @ViewChild('layoutMenuScroller') layoutMenuScrollerViewChild: ScrollPanel;

    // index của "nhóm menu item" (menu level 0) hiện đang activated
    activatedMenuGroupIndex: number;

    isMenuClicked: boolean = false;

    appMenuModel: any[];

    constructor(
        private translate: TranslateService,
        private _oauthService: OAuthService,
        private auth: AuthenticationService,
        private _userService: UserService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _heartBeatService: HeartBeatService,
        public _commonService: CommonService,
        private _menuService: HtMenuService
    ) {
        this.translate.setDefaultLang('vn');
        this.translate.use('vn');

        console.log(environment);
        // Config for authentication
        this.configureWithNewConfigApi();
    }

    ngAfterViewInit() {
        // setTimeout(() => { this.layoutMenuScrollerViewChild.moveBar(); }, 100);
    }

    async ngOnInit() {
        // if (!environment.production) {
        //     // for dev evironment
        //     setInterval(() => {
        //         this._heartBeatService.sendHeartBeat();
        //     }, 70000);
        // }      
        // await this.getMenu();
    }

    // async getMenu() {
    //     const cacheVal = sessionStorage.getItem('htMenu' + environment.clientDomain.idPhanhe.toString());
    //     if (cacheVal !== undefined && cacheVal !== null) {
    //         this.appMenuModel = await JSON.parse(cacheVal);
    //     } else {
    //         await this._menuService.getByIdPhanHe(environment.clientDomain.idPhanhe, 0).then(rs => {
    //             if (rs.success) {
    //                 this.appMenuModel = rs.data;
    //                 sessionStorage.setItem('htMenu' + environment.clientDomain.idPhanhe.toString(), JSON.stringify(rs.data));
    //             }
    //         });
    //     }
    // }

    private configureWithNewConfigApi() {
        this._oauthService.setStorage(localStorage);
        this._oauthService.configure(authConfig);
        this._oauthService.tokenValidationHandler = new JwksValidationHandler();

        if (!this._oauthService.hasValidAccessToken()) {
            this._router.navigate(['/tai-khoan/dang-nhap']);
        } else {
            this._oauthService.tryLogin().then(rs => {
                this._oauthService.events.subscribe((event: OAuthInfoEvent) => {
                    if (event.type === 'token_expires') {
                        this._oauthService.silentRefresh();
                    }
                });
            });

            this._userService.returnPromises();
            this.pageLoaded = true;
        }
    }

    onMenuCollapseToggleClick() {
        if (this.isDesktop()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        } else {
            this.staticMenuMobileActive = !this.staticMenuMobileActive;
        }
    }

    onSwitchModule(moduleCode) {
        switch (moduleCode) {
            case 0: {// administrator
                this._router.navigate(['/admin/master-data/single']);
                break;
            }
            default: { // frontend
                this._router.navigate(['/']);
                break;
            }
        }
    }

    onChangePassword(event) {
        // event.preventDefault();
        // this._userService.getId()
        //     .then(response => {
        //         this.changePassword.showPopup(response);
        //     });
    }

    onTopbarLogout() {
        // this._authenService.logout();
        this.auth.logout();
        this._router.navigate(['/tai-khoan/dang-nhap']);
    }

    hideOverlayMenu() {
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this._sub.unsubscribe();
    }

    newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // tslint:disable-next-line:no-bitwise
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    togglePageContentExpansionMode() {
        this.pageContentExpansionModeActive = !this.pageContentExpansionModeActive;
    }
}

