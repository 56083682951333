import { Injector } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as i0 from "@angular/core";
var MenuOrientation;
(function (MenuOrientation) {
    MenuOrientation[MenuOrientation["STATIC"] = 0] = "STATIC";
    MenuOrientation[MenuOrientation["OVERLAY"] = 1] = "OVERLAY";
    MenuOrientation[MenuOrientation["SLIM"] = 2] = "SLIM";
    MenuOrientation[MenuOrientation["HORIZONTAL"] = 3] = "HORIZONTAL";
})(MenuOrientation || (MenuOrientation = {}));
export class CommonService {
    constructor() {
        this.layoutMode = MenuOrientation.STATIC;
    }
    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
    randomString() {
        return this.guid();
    }
    stringFormat(inputString, ...args) {
        // tslint:disable-next-line:forin
        for (const index in args) {
            inputString = inputString.replace(`{${index}}`, args[index]);
        }
        return inputString;
    }
    htmlEncode(str) {
        return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '&quot;');
    }
    // Lấy về giá trị theo path ''
    getValueByPath(obj, path) {
        const paths = path.split('.');
        for (let i = 0; i < paths.length; i++) {
            try {
                obj = obj[paths[i]];
            }
            catch (err) {
                obj = null;
            }
        }
        return obj;
    }
    addDays(date, days) {
        const cloned = new Date(date);
        cloned.setDate(date.getDate() + days);
        return cloned;
    }
    addWorkDays(date, days) {
        const cloned = new Date(date);
        while (days > 0) {
            cloned.setDate(cloned.getDate() + 1);
            if (cloned.getDay() !== 0 && cloned.getDay() !== 6) {
                days -= 1;
            }
        }
        return cloned;
    }
    exportToCSV(datas, columns, fileName) {
        let headerString = '';
        // Duyệt cột để thêm vào header
        columns.forEach(c => {
            headerString += c.header + ',';
        });
        const rowsString = [];
        datas.forEach(d => {
            let rowString = '';
            columns.forEach(c => {
                // rowString += (typeof d[c.field]).toString() + ',';
                let colVal = '';
                if (c.dataPath) {
                    const colValTmp = this.getValueByPath(d, c.dataPath);
                    if (colValTmp) {
                        colVal = colValTmp;
                    }
                }
                else if (d[c.field]) {
                    colVal = d[c.field];
                }
                // Format Date
                if (c.dateFormat) {
                    const datePipe = new DatePipe('en-US');
                    colVal = datePipe.transform(colVal, c.dateFormat);
                }
                // Format mapping
                if (c.dataMapping) {
                    c.dataMapping.forEach(dm => {
                        if (dm.id === d[c.field]) {
                            colVal = dm.name.toString().replace(',', '.').replace('\n', '').replace('\r', '');
                        }
                    });
                }
                if (colVal) {
                    rowString += colVal.toString().replace(',', '.').replace('\n', '').replace('\r', '') + ',';
                }
                else {
                    rowString += '' + ',';
                }
            });
            rowsString.push(rowString);
        });
        let csv = headerString + '\n';
        for (const row of rowsString) {
            csv += row + '\n';
        }
        const blob = new Blob(['\uFEFF', csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', fileName + '.csv');
        document.body.appendChild(link); // Required for FF
        link.click();
    }
    exportToCSV2(datas, columns, fileName) {
        let headerString = '';
        // Duyệt cột để thêm vào header
        columns.forEach(c => {
            headerString += c.header + ',';
        });
        const rowsString = [];
        datas.forEach(d => {
            let rowString = '';
            columns.forEach(c => {
                // rowString += (typeof d[c.field]).toString() + ',';
                let colVal = '';
                if (c.dataPath) {
                    const colValTmp = this.getValueByPath(d, c.dataPath);
                    if (colValTmp) {
                        colVal = colValTmp;
                    }
                }
                else if (d[c.field]) {
                    colVal = d[c.field];
                }
                // Format Date
                if (c.dateFormat) {
                    const datePipe = new DatePipe('en-US');
                    colVal = datePipe.transform(colVal, c.dateFormat);
                }
                // Format mapping
                if (c.dataMapping) {
                    c.dataMapping.forEach(dm => {
                        if (dm.id === d[c.field]) {
                            colVal = dm.name.toString().replace(',', '.').replace('\n', '').replace('\r', '');
                        }
                    });
                }
                if (colVal) {
                    rowString += colVal.toString().replace(',', '.').replace('\n', '').replace('\r', '') + ',';
                }
                else {
                    rowString += '' + ',';
                }
            });
            rowsString.push(rowString);
        });
        let csv = headerString + '\n';
        for (const row of rowsString) {
            csv += row + '\n';
        }
        const blob = new Blob(['\uFEFF', csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', fileName + '.csv');
        document.body.appendChild(link); // Required for FF
        link.click();
    }
    exportToCSVGroup(datas, columns, fileName) {
        let headerString = '';
        // Duyệt cột để thêm vào header
        for (let i = 0; i < columns.length; i++) {
            headerString += columns[i].header + ',';
        }
        const rowsString = [];
        datas.forEach(subData => {
            rowsString.push(subData.groupName);
            rowsString.push(headerString);
            subData.data.forEach(d => {
                let rowString = '';
                columns.forEach(c => {
                    let colVal = '';
                    if (c.dataPath) {
                        const colValTmp = this.getValueByPath(d, c.dataPath);
                        if (colValTmp) {
                            colVal = colValTmp;
                        }
                    }
                    else if (d[c.field]) {
                        colVal = d[c.field];
                    }
                    // Format Date
                    if (c.dateFormat) {
                        const datePipe = new DatePipe('en-US');
                        colVal = datePipe.transform(colVal, c.dateFormat);
                    }
                    // Format mapping
                    if (c.dataMapping) {
                        c.dataMapping.forEach(dm => {
                            if (dm.id === d[c.field]) {
                                colVal = dm.name.toString().replace(',', '.').replace('\n', '').replace('\r', '');
                            }
                        });
                    }
                    if (colVal) {
                        rowString += colVal.toString().replace(',', '.').replace('\n', '').replace('\r', '') + ',';
                    }
                    else {
                        rowString += '' + ',';
                    }
                });
                rowsString.push(rowString);
            });
            rowsString.push('\n');
        });
        // let csv = headerString + '\n';
        let csv = '';
        for (const row of rowsString) {
            csv += row + '\n';
        }
        const blob = new Blob(['\uFEFF', csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', fileName + '.csv');
        document.body.appendChild(link); // Required for FF
        link.click();
    }
    convertToSelectItem(data, valueField, textField = '', textField2 = '', prefixTextField2 = '', allowNull = true) {
        if (!textField || textField === '') {
            textField = valueField;
        }
        const tempArr = [];
        if (allowNull) {
            tempArr.push({ label: '', value: '' });
        }
        // tslint:disable-next-line:forin
        for (const ind in data) {
            const item = data[ind];
            tempArr.push({ label: this.getLabel(item, textField, textField2, prefixTextField2), value: item[valueField] });
        }
        return tempArr;
    }
    getLabel(item, textField, textField2, prefixTextField2) {
        if (textField2) {
            return item[textField] + ' - ' + prefixTextField2 + ' ' + item[textField2];
        }
        else {
            return item[textField];
        }
    }
    convertToSelectItemMasterData(data, valueField = 'id', textField = 'name') {
        return this.convertToSelectItem(data, valueField, textField);
    }
    handleError(error, injector) {
        // console.error('Có lỗi xảy ra', error);
        // if (error.status === 401) {
        //     const authenService = injector.get(AuthenService);
        //     authenService.logout();
        // } else {
        // }
        return Promise.reject(error);
    }
    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;
        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        }
        else {
            this.activeTopbarItem = item;
        }
        event.preventDefault();
    }
    isHorizontal() {
        return this.layoutMode === MenuOrientation.HORIZONTAL;
    }
    isSlim() {
        return this.layoutMode === MenuOrientation.SLIM;
    }
    hideOverlayMenu() {
        this.rotateMenuButton = false;
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    }
    onLayoutClick() {
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }
        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.resetMenu = true;
            }
            if (this.overlayMenuActive || this.staticMenuMobileActive) {
                this.hideOverlayMenu();
            }
            this.menuHoverActive = false;
        }
        this.topbarItemClick = false;
        this.menuClick = false;
    }
    delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    /** Begin disable editor tab */
    setAutofocusFirstControl(idElement) {
        setTimeout(() => {
            const viewRef = document.getElementById(idElement);
            if (viewRef != null) {
                const input = viewRef.querySelector('input');
                if (input && input.type != "radio") {
                    input.focus();
                }
                else {
                    const texateara = viewRef.querySelector('textarea');
                    if (texateara) {
                        texateara.focus();
                    }
                }
            }
        }, 500);
    }
    getTabDisabledHandler() {
        // return {
        //     key    : 9,
        //     handler: function (arg1, arg2) {
        //         const next = JQuery(document.activeElement).closest('p-editor').parent().next().find(':tabbable, :focusable').first();
        //         if (next) {
        //             next.focus();
        //         }
        //         return false;
        //     }
        // };
    }
    addTabDisabledHandler(event) {
        // this.disableTabToolbarEditor();
        // event.editor.keyboard.bindings[9].length = 0;
        // event.editor.keyboard.bindings[9].push(this.getTabDisabledHandler());
    }
    disableTabToolbarEditor() {
        // jQuery(".ql-toolbar").find(":button").attr("tabindex", "-1");
    }
    /** End disable editor tab */
    processDateInput(inputValue) {
        if (!inputValue)
            return null;
        if (inputValue.length === 8) { // 01012019
            const day = +inputValue.substr(0, 2);
            const month = +inputValue.substr(2, 2);
            const year = +inputValue.substr(4, 4);
            if (month > 12 || month < 1)
                return null;
            const maxDay = this.getMaxDayInMonthAndYear(month, year);
            if (day > maxDay || day < 1)
                return null;
            return new Date(year, month - 1, day);
        }
        else if (inputValue.length === 6) { // 010119
            const day = +inputValue.substr(0, 2);
            const month = +inputValue.substr(2, 2);
            const year = +('20' + inputValue.substr(4, 2).toString());
            if (month > 12 || month < 1)
                return null;
            const maxDay = this.getMaxDayInMonthAndYear(month, year);
            if (day > maxDay || day < 1)
                return null;
            return new Date(year, month - 1, day);
        }
        else if (inputValue.length === 4 && inputValue.indexOf('-') === -1) { // 0101
            const day = +inputValue.substr(0, 2);
            const month = +inputValue.substr(2, 2);
            const year = this.getCurrentYear();
            if (month > 12 || month < 1)
                return null;
            const maxDay = this.getMaxDayInMonthAndYear(month, year);
            if (day > maxDay || day < 1)
                return null;
            return new Date(year, month - 1, day);
        }
        else if (inputValue.length === 1
            || inputValue.length === 2
            || inputValue.length === 3
            || (inputValue.length === 4 && inputValue.indexOf('-') === 0)) {
            // -1 - -999
            // 1 - 9
            // 10 - 99
            // 100 - 999
            return this.addDays(new Date(), +inputValue);
        }
        return null;
    }
    getMaxDayInMonthAndYear(month, year) {
        const d = new Date(year, month, 0);
        return d.getDate();
    }
    getCurrentYear() {
        return new Date().getFullYear();
    }
    getCurrentMonth() {
        return new Date().getMonth();
    }
    getDynamicFormId(service, entity, action) {
        return `${service}_${entity}_${action}`.toUpperCase();
    }
    getDefaultDynamicFormId(action) {
        return `DEFAULT_${action}`.toUpperCase();
    }
    cloneObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    }
    standardizedJson(inputJson) {
        let data = inputJson.replace(/ObjectId\((.*?)\)/gm, '$1');
        data = data.replace(/ISODate\((.*?)\)/gm, '$1');
        data = data.replace(/NumberLong\((.*?)\)/gm, '$1');
        return data;
    }
}
CommonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommonService_Factory() { return new CommonService(); }, token: CommonService, providedIn: "root" });
