import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Message, } from 'primeng/api';

@Component({

    // tslint:disable-next-line: component-selector
    selector: 'validation-summary',
    templateUrl: './validation-summary.component.html',
    styleUrls: ['./validation-summary.component.scss'],
})
export class ValidationSummaryComponent implements OnInit {


    @Input() formGroup: FormGroup;
    @Input() formElement: any;
    // @Input() ngClass = 'ui-g-12 ui-md-12';
    // @Input() formId: number;

    errors: Message[] = [];

    // errorString = '';

    constructor(
    ) { }

    ngOnInit() {
        this.errors = [];
    }

    resetErrorMessages() {
        this.errors = [];
    }

    generateErrorMessages(formGroup: FormGroup) {

        // this.errorString = '';

        for (const controlName in formGroup.controls) {
            const control = formGroup.controls[controlName];
            const errors = control.errors;

            if (errors === null || errors.count === 0) {
                continue;
            }
            // Handle the 'required' case
            const controlObj = this.formElement.querySelector(`[for='${controlName}']`);
            const controlText = controlObj ? controlObj.innerText : controlName;

            if (errors.required) {
                this.errors.push({ severity: 'error', summary: controlText, detail: `không được để trống` });
                // this.errorString += `${controlText} không được để trống <br />`;
                // this._messageService.showError(`${controlText} không được để trống`, this.toastKey);
            }


            if (errors.duplicate) {
                this.errors.push({ severity: 'error', summary: controlText, detail: `đã tồn tại` });
                // this.errorString += `${controlText} đã tồn tại <br />`;
            }


            if (errors.pattern) {
                this.errors.push({ severity: 'error', summary: controlText, detail: `không đúng định dạng` });
                // this.errorString += `${controlText} không đúng định dạng <br />`;
            }


            if (errors.nomatch) {
                this.errors.push({ severity: 'error', summary: controlText, detail: `đang không trùng nhau` });
                // this.errorString += `${controlText} đang không trùng nhau <br />`;
            }


            if (errors.incorrect) {
                this.errors.push({ severity: 'error', summary: controlText, detail: `không đúng` });
                // this.errorString += `${controlText} không đúng<br />`;
            }

            // Handle 'minlength' case
            if (errors.minlength) {
                this.errors.push({ severity: 'error', summary: controlText, detail: `không được dài quá ${errors.minlength.requiredLength} ký tự.` });
                // this.errorString += `${controlText} không được dài quá ${errors.minlength.requiredLength} ký tự. <br />`;
                // this._messageService.showError(`${controlText} không được dài quá ${errors.minlength.requiredLength} ký tự.`, this.toastKey);
            }
        }

    }
    showValidationSummary() {
        this.resetErrorMessages();
        this.generateErrorMessages(this.formGroup);
    }
}
