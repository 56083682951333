var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ComponentBase } from './component-base';
import { NotifierService } from '../../services/notifier.service';
import { OnInit, Injector, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomRouterService } from '../../services/custom-router.service';
import { takeUntil } from 'rxjs/operators';
import { ListHelperService } from '../../services/list-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../services/common.service';
import { ExportService } from '../../services/export.service';
import { CONFIG_CALENDAR_VIETNAMESE } from '../../../../../../src/app/config/calendar.config';
export class SecondPageIndexBase extends ComponentBase {
    constructor(_baseService, _injector) {
        super();
        this._baseService = _baseService;
        this._injector = _injector;
        this.query = '';
        this.openSearchAdv = false;
        this.isCheckAll = false;
        this.isViewActivity = false;
        this.isViewSetting = false;
        this.ids = [];
        this.totalRecord = 0;
        this.pageIndex = 1;
        this.pageSize = 20;
        this.cols = [];
        this.isMultiEdit = false;
        this.dataSource = [];
        this.dataExport = [];
        this.openSelectCheck = false;
        this.orderCol = '';
        this.isDesc = true;
        this.isLoading = false;
        this.selectedItems = [];
        this.isCollapsed = false;
        this.isIgnoreClientCache = false;
        this.popupSize = { width: '1170', height: '500' };
        this.vi = CONFIG_CALENDAR_VIETNAMESE;
        this.openColumnList = false;
        this.arrTrangThai = [
            { label: 'Sử dụng', value: 1 },
            { label: 'Không Sử dụng', value: 2 },
            { label: 'Bị xóa', value: 3 },
            { label: '--Tất cả--', value: 0 },
        ];
        this.trangThai = 1;
        this.listItemNumberPerPage = [
            { label: '10', value: 10 },
            { label: '20', value: 20 },
            { label: '50', value: 50 },
            { label: '100', value: 100 },
            { label: 'Tất cả' },
        ];
        this.actionHiddenElements = [];
        this.splitExportItems = [
            { label: 'Excel', icon: 'fa fa-file-excel' },
            { label: 'Word', icon: 'fa fa-file-word' }
        ];
        this._notifierService = this._injector.get(NotifierService);
        this._activatedRoute = this._injector.get(ActivatedRoute);
        this._customRouterService = this._injector.get(CustomRouterService);
        this._listHelperService = this._injector.get(ListHelperService);
        this._commonService = this._injector.get(CommonService);
        this._translateService = this._injector.get(TranslateService);
        this._exportService = this._injector.get(ExportService);
        this._router = this._injector.get(Router);
        this.actionHiddenElements = [
            { text: this._translateService.instant('LIST.PRINT'), icon: 'ci-printer', action: this.onPrint },
            { text: this._translateService.instant('LIST.IMPORT_EXCEL'), icon: 'ci-upload', action: this.onImport },
        ];
    }
    onClick(event, targetElement) {
        if (this.columnListContainerElement) {
            if (!this.columnListContainerElement.nativeElement.contains(targetElement)) {
                this.openColumnList = false;
            }
        }
        if (this.bulkSelectContainerElement) {
            if (!this.bulkSelectContainerElement.nativeElement.contains(targetElement)) {
                this.openSelectCheck = false;
            }
        }
    }
    ngOnInit() {
        this._activatedRoute.fragment
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((fragments) => {
            let query = this._customRouterService.parseFragment(fragments).searchKey;
            if (query === null || query === undefined) {
                query = '';
            }
            this.query = query;
            this.getData();
        });
        this.getPopupSize();
    }
    getPopupSize() {
        if (this.popupSize && this.popupSize.width) {
            if (window.innerWidth < Number(this.popupSize.width)) {
                this.popupSize.width = '100%';
            }
            else {
                this.popupSize.width = this.popupSize.width + 'px';
            }
        }
        this.popupSize.height = (window.innerHeight - 200) + 'px';
    }
    toggleSearh() {
        this.openSearchAdv = !this.openSearchAdv;
    }
    toggleSelectCheck() {
        this.openSelectCheck = !this.openSelectCheck;
    }
    getData(limit) {
        this.isLoading = true;
        this.selectedItems = [];
        this.ids = [];
        this.resetBulkSelect();
        this._baseService.get(this.query, this.trangThai, this.pageIndex, this.pageSize, this.orderCol, this.isDesc)
            .then(response => {
            this.dataSource = response.data;
            if (response.totalRecord || response.totalRecord === 0) {
                this.totalRecord = response.totalRecord;
            }
            this.afterGetData();
            this.isLoading = false;
        }, error => {
            this._notifierService.showHttpUnknowError();
            this.isLoading = false;
        });
    }
    exportToExcel(fileName) {
        if (fileName === null || fileName === undefined) {
            fileName = 'export';
        }
        if (this.selectedItems.length === 0) {
            this._notifierService.showWarning(this._translateService.instant('Bạn chưa chọn dòng để xuất dữ liệu'));
        }
        else {
            this._exportService.export(this.selectedItems, this.cols, fileName);
        }
    }
    exportAllToExcel() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.onBeforeExportAllToExcel();
            if (this.dataExport.length == 0) {
                this._baseService.get(this.query, this.trangThai, 1, this.totalRecord, this.orderCol, this.isDesc)
                    .then(response => {
                    if (response.data.length === 0) {
                        this._notifierService.showWarning(this._translateService.instant('Không có dữ liệu để xuất !'));
                    }
                    else {
                        this._exportService.export(response.data, this.cols, 'export');
                    }
                }, error => {
                    this._notifierService.showHttpUnknowError();
                });
            }
            else {
                this._exportService.export(this.dataExport, this.cols, 'export');
            }
        });
    }
    onBeforeExportAllToExcel() {
        return __awaiter(this, void 0, void 0, function* () {
        });
    }
    delete() {
        if (this.selectedItems.length > 0) {
            const id = this.selectedItems[0].id;
            this.isLoading = true;
            this._notifierService.showDeleteConfirm().then(rs => {
                this._baseService.delete(id)
                    .then(response => {
                    this.getData();
                    this._notifierService.showDeleteDataSuccess();
                    this.isLoading = false;
                }, error => {
                    this._notifierService.showHttpUnknowError();
                    this.isLoading = false;
                });
            });
            this.selectedItems = [];
        }
    }
    deletelstMany() {
        this.isLoading = true;
        let lstId = '';
        for (const item of this.selectedItems) {
            lstId += item.id + ',';
        }
        lstId = lstId.substring(0, lstId.length - 1);
        this._baseService.deleteMany(lstId).then(rs => {
            if (rs.success) {
                this.ids = [];
                this.isMultiEdit = false;
                this.getData();
                this._notifierService.showDeleteDataSuccess();
            }
            this.isLoading = false;
        }, error => {
            this._notifierService.showHttpUnknowError();
            this.isLoading = false;
        });
        this.selectedItems = [];
    }
    afterGetData() {
    }
    toggleSearch() {
        this.openSearchAdv = !this.openSearchAdv;
    }
    toggleColumnList() {
        this.openColumnList = !this.openColumnList;
    }
    onSearch() {
        this.pageIndex = 1;
        this.getData();
        // this.openSearchAdv = false;
    }
    goToPage(event) {
        this.pageIndex = (event.first / event.rows) + 1;
        this.pageSize = event.rows;
        this.getData();
    }
    onNext() {
        this.pageIndex++;
        this.getData();
    }
    onPrev() {
        this.pageIndex--;
        this.getData();
    }
    onSort(event) {
        this.orderCol = event.field;
        this.isDesc = event.order === 1 ? true : false;
        this.getData();
    }
    onChangeSize(n) {
        if (this.pageSize === undefined) {
            this.pageSize = this.totalRecord;
        }
        if (n > 0) {
            this.pageSize = n;
        }
        this.pageIndex = 1;
        this.getData();
    }
    viewActivities() {
        this.isViewActivity = !this.isViewActivity;
    }
    viewSettings() {
        this.isViewSetting = !this.isViewSetting;
    }
    onPage(event) {
        this.pageIndex = (event.first / event.rows) + 1;
        this.pageSize = event.rows;
        this.getData();
    }
    checkItem(item) {
        if (item.checked) {
            this.ids.push(item.id);
            this.selectedItems.push(item);
            item.checked = true;
        }
        else {
            this.selectedItems.splice(this.selectedItems.indexOf(x => x.id === item.id), 1);
            this.ids.splice(this.ids.indexOf(item.id), 1);
            item.checked = false;
        }
        this.isMultiEdit = this.ids.length > 0 ? true : false;
        this.isCheckAll = this.ids.length === this.dataSource.length ? true : false;
    }
    onCheckAll(ev) {
        if (ev.target.checked) {
            this.isCheckAll = true;
            this.ids = [];
            this.selectedItems = this.dataSource;
            for (let i = 0; i < this.dataSource.length; i++) {
                this.dataSource[i].checked = true;
                this.ids.push(this.dataSource[i].id);
            }
        }
        else {
            this.isCheckAll = false;
            this.ids = [];
            this.selectedItems = [];
            for (let i = 0; i < this.dataSource.length; i++) {
                this.dataSource[i].checked = false;
            }
        }
        this.isMultiEdit = this.ids.length > 0 ? true : false;
    }
    onSelectionChanged(event) {
        this.selectedItems = event;
    }
    containSelectItem(item) {
        return this.selectedItems.findIndex(x => x.id === item.id) > -1;
    }
    onPopupClosed(data) {
        this.selectedItems = [];
        if (data) {
            this.getData();
        }
    }
    onPrint() {
        console.log("onPrint");
    }
    onPrintE(element) {
    }
    convertDataToOptions(options, data, fieldName) {
        if (data.length > 0) {
            data.map(item => options.push({ label: item[fieldName], value: item.id }));
        }
    }
    // table filter
    //------------------------
    closeTableFilter() {
        this.openSearchAdv = !!this.preventTableFilterClosing;
    }
    delayTableFilterClosing(delayTime = 100) {
        clearTimeout(this.tableFilterDelayClosingTimer);
        this.preventTableFilterClosing = true;
        this.tableFilterDelayClosingTimer = setTimeout(() => {
            this.preventTableFilterClosing = false;
        }, delayTime);
    }
    // table cols visibility
    //------------------------
    getVisibleColsNumber() {
        let items = this.cols.filter(item => item.visible);
        return items.length;
    }
    resetColsVisibility() {
        this.cols.forEach(item => item.visible = false);
    }
    // table row selection
    //------------------------
    checkSelectionState() {
        this.isCheckAll = this.selectedItems.length === 0 ? null :
            (this.selectedItems.length > 0 && this.selectedItems.length === this.dataSource.length);
        this.isMultiEdit = this.selectedItems.length > 1;
    }
    onChangeBulkSelection() {
        if (this.isCheckAll === null || this.isCheckAll) {
            this.resetBulkSelect();
            this.dataSource.forEach(item => this.selectedItems.push(item));
        }
        else {
            this.resetBulkSelect();
        }
        this.checkSelectionState();
    }
    // helper
    //------------------------
    consoleLog(event) {
        console.log(event);
    }
    randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    getRandomItemFromList(listItem) {
        let index = Math.floor(Math.random() * listItem.length);
        return listItem[index];
    }
    resetBulkSelect() {
        this.ids = [];
        this.selectedItems = [];
        this.isCheckAll = null;
    }
    onPrePage() {
        if (this.pageIndex > 1) {
            this.pageIndex--;
            this.getData();
        }
    }
    onNextPage() {
        if (this.pageIndex <= this.totalRecord / this.pageSize) {
            this.pageIndex++;
            this.getData();
        }
    }
    onImport() {
        console.log("onImport");
    }
    onCancelSearch() {
        this.openSearchAdv = !this.openSearchAdv;
    }
    add() { }
    ;
    edit() { }
    ;
    keyEventAdd(event) {
        console.log(event);
        this.add();
    }
    keyEventEdit(event) {
        console.log(event);
        this.edit();
    }
    keyEventSearch(event) {
        this.openSearchAdv = !this.openSearchAdv;
    }
}
