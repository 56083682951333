import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../app.component';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { CustomRouterService, SvUser, UserService, SignalRService, NotificationService, NotifierService } from 'sv-shared';
import { Subscription } from 'rxjs';

enum SidebarTabs {
    NOTIFICATION,
    ACTIVITY
}

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss']
})
export class AppTopBarComponent implements OnInit, OnDestroy, AfterViewInit {
    environment = environment;
    searchKey = '';

    _unSubscribeAll = new Subject<any>();
    _sub: Subscription;
    fileApi = '';

    @ViewChild('profile', { static: false })
    profileElement: ElementRef;

    @ViewChild('notification', { static: false })
    notificationElement: ElementRef;

    displayName = 'Admin';
    position = 'Quản trị viên';
    avatarUrl = 'assets/images/avatar.jpg';
    currentUser = new SvUser;

    showDropdown = {
        subsystem: false,
        userSetting: false,
        rightSidebar: false
    };

    SidebarTabs = SidebarTabs;

    currentSidebarTab: SidebarTabs = SidebarTabs.NOTIFICATION;

    maxNotification = 10;
    totalUnRead = 0;
    url = '';
    urldetail = '';
    notifications = [];
    userId: string;
    currentItem: any;

    // subsystems: any[] = [
    //     {
    //         icon: 'usp usp-integration-03',
    //         title: 'Quản lý NCKH',
    //     },
    //     {
    //         icon: 'usp usp-finance-graph',
    //         title: 'Quản lý Tài chính',
    //     },
    //     {
    //         icon: 'usp usp-backup-01',
    //         title: 'QL Hành chính',
    //     },
    //     {
    //         icon: 'usp usp-hr-02',
    //         title: 'Quản lý Nhân sự',
    //     }
    // ];

    constructor(
        public app: AppComponent,
        private _activatedRoute: ActivatedRoute,
        private _customRouteService: CustomRouterService,
        private _router: Router,
        private _userService: UserService,
        private _signalRService: SignalRService,
        private _notificationService: NotificationService,
        protected _notifierService: NotifierService,
    ) {
        // this.fileApi = this.environment.apiDomain.fileEndpoint;
        this._signalRService.initNotificationHub();
    }

    ngOnInit(): void {

        this._userService.getCurrentUser().then(rs => {
            if (rs.displayName) {
                this.currentUser = rs;
                this.displayName = rs.displayName;
                this.position = rs.position;
                this.getImageUrl();
            }
        });

        this._userService.getId().then(rs => {
            this.userId = rs.toString();
            this._signalRService.start(
                environment.signalrConfig.topic.notificationCreated,
                'NotificationHub',
                this.notifyTrigger.bind(this)
            );

            this.refreshNotification();
        });
    }

    getImageUrl() {
        if (this.currentUser && this.currentUser.avatar) {
            this.avatarUrl = `${this.fileApi}/file/public/${this.currentUser.avatar}/Download`;
        } else {
            this.avatarUrl = 'assets/images/avatar.jpg';
        }
    }


    ngAfterViewInit(): void {

    }

    ngOnDestroy(): void {
        this._unSubscribeAll.next();
        this._unSubscribeAll.complete();
        if (this._sub) {
            this._sub.unsubscribe();
        }
    }

    // Subsystem
    //-------------------

    closeSubsystemsDropdown(event) {
        this.showDropdown.subsystem = false;
    }

    toggleSubsystemsDropdown() {
        this.showDropdown.subsystem = !this.showDropdown.subsystem;
    }

    // user setting
    //-------------------

    closeUserSettingDropdown(event) {
        this.showDropdown.userSetting = false;
    }

    toggleUserSettingDropdown() {
        this.showDropdown.userSetting = !this.showDropdown.userSetting;
    }

    // Sidebar
    //-------------------

    closeRightSidebar(event) {
        this.showDropdown.rightSidebar = false;
    }

    toggleRightSidebar() {
        this.showDropdown.rightSidebar = !this.showDropdown.rightSidebar;
    }

    //Notification
    notifyTrigger(data: any) {
        // this.notifications.unshift(data);

        // if (this.notifications.length > this.maxNotification) {
        //     this.notifications.pop();
        // }
        // this.totalUnRead++;
        console.log(data);
        if (data !== null) {
            if (data.userId !== null) {
                if (data.userId.indexOf(',' + this.userId + ',') !== -1) {
                    console.log('refreshNotification:  ' + this.userId);
                    this.refreshNotification();
                    this._notifierService.showSuccess("Có thông báo mới");
                }
            } else {
                console.log('refreshNotification:  All');
                this.refreshNotification();
            }
        }
    }

    refreshNotification() {
        this._notificationService.getsToUserId(Number(this.userId), 'false', 1, 9999)
            .then(result => {
                this.notifications = result.data;
                this.totalUnRead = result.totalRecord;
            });
    }

    readAll(event) {
        event.preventDefault();
        event.stopPropagation();

        this._notificationService.readAll().then(rs => {
            this.notifications.forEach(element => {
                if (element.read != null) {
                    element.read.push({ userId: this.userId });
                } else {
                    element.read = [{ userId: this.userId }];
                }
            });
        });

        this.totalUnRead = 0;
    }

    readMessage(id: number) {
        this._notificationService.readMessage(id).then(rs => {
            if (rs.success) {
                this.totalUnRead -= 1;
                this.notifications = this.notifications.filter(x => x.id !== id);
            }
        });
    }

    hasChildren() {
        if (this.notifications) {
            for (let i = 0; i < this.notifications.length; i++) {
                let item = this.notifications[i];

                if (item.items) {
                    return true;
                }
            }
        }

        return false;
    }

    // helper
    //------------------------
    randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    getRandomItemFromList(listItem) {
        let index = Math.floor(Math.random() * listItem.length);
        return listItem[index];
    }
}
