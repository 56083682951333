import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutes } from './app.routes';
import { AppComponent } from './app.component';
import { AppMenuComponent, AppSubMenuComponent } from './app-content/menu/app.menu.component';
import { AppTopBarComponent } from './app-content/topbar/app.topbar.component';
import { AppFooterComponent } from './app-content/footer/app.footer.component';
import { AppProfileComponent } from './app-content/profile/app.profile.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
    MultiTranslateHttpLoader,
    SendAccessTokenInterceptor,
    SvComponentModule,
    UserFormatPipe,
    UserService,
} from 'sv-shared';
import { environment } from '../environments/environment';
import { OAuthModule } from 'angular-oauth2-oidc';
import { SignalRModule } from 'ng2-signalr';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import { MessageService } from 'primeng/components/common/messageservice';
import { DatePipe } from '@angular/common';
import { QuicklinkModule } from 'ngx-quicklink';
import { UnauthorizeComponent } from './specific-page/Unauthorize/Unauthorize.component';
import { GlobalService } from './services/global.service';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { ErrorPageNotFoundComponent } from './specific-page/not-found/not-found.component';
import { ToastModule } from "primeng/toast";
import { AutoCompleteModule, CheckboxModule, ConfirmDialogModule, OverlayPanelModule, ScrollPanelModule, PanelModule } from 'primeng/primeng';
import { ClickOutsideModule } from "ng-click-outside";
import { AppMenuSearchBoxComponent } from "./app-content/menu-search-box/app.menu-search-box.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppNotificationComponent } from "./app-content/notification/app.notification.component";
import { LoginComponent } from './specific-page/login/login.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthGuardLoginService } from './services/auth-guard-login.service';
import { SV_COMPONENT_CONFIG } from './config/sv-component.config';
import { DashboardComponent } from './dashboard/dashboard.component';

export function createTranslateLoader(http: HttpClient) {
    return new MultiTranslateHttpLoader(http,
        [
            { prefix: './assets/i18n/', suffix: '.json' },
            { prefix: './assets/i18n/shared/', suffix: '.json' }
        ]
    );
}

export function getTnComponentConfigProvider() {
    return SV_COMPONENT_CONFIG;
}

@NgModule({
    imports: [
        BrowserModule,
        AppRoutes,
        HttpClientModule,
        ToastModule,
        ConfirmDialogModule,
        OverlayPanelModule,
        AutoCompleteModule,
        FormsModule,
        BrowserAnimationsModule,
        CheckboxModule,
        ClickOutsideModule,
        ScrollPanelModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [environment.apiDomain.gateway],
                sendAccessToken: false
            }
        }),
        SvComponentModule.forRoot(getTnComponentConfigProvider),
        SignalRModule,
        QuicklinkModule,
        ReactiveFormsModule,
        SvComponentModule,
        PanelModule
    ],
    declarations: [
        AppComponent,
        DashboardComponent,
        LoginLayoutComponent,
        DefaultLayoutComponent,
        AppNotificationComponent,
        AppMenuSearchBoxComponent,
        AppMenuComponent,
        AppSubMenuComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppProfileComponent,
        UnauthorizeComponent,
        ErrorPageNotFoundComponent,
        LoginComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SendAccessTokenInterceptor,
            multi: true
        },
        AuthGuardService,
        AuthGuardLoginService,
        MessageService,
        ConfirmationService, DatePipe, UserFormatPipe, UserService, GlobalService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
