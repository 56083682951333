import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { FsFilesService } from '../../../services/fs-files.service';
import { SecondPageEditBase } from '../../../classes/base/second-page-edit-base';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent extends SecondPageEditBase implements OnInit {

    progressValue = 0;
    apiUploadUrl = '';
    accept = 'image/*,.doc,.docx,.xls,xlsm,.xlsx,ppt,pptm,pptx,.pdf,.rar,.zip,docm,dot,dotm,dotx,epub,htm,html,mht,odt,ott,rtf,txt,djvu,xps,csv,fods,ods,ots,xlt,xltm,xltx,fodp,odp,otp,pot,potm,potx,pps,ppsm,ppsx';
    maxFileSize = 100000000;
    folderId = 0;
    isGlobal = false;
    uploadedFiles: any[] = [];
    @ViewChild('fileControl', { static: false }) fileControl: FileUpload;
    constructor(
        protected _FsFilesService: FsFilesService,
        protected _injector: Injector
    ) {
        super(_FsFilesService, _injector);
    }

    ngOnInit() {
        this.apiUploadUrl = this._FsFilesService.getUploadUrl();
    }

    onShowPopup(data: any) {
        this.folderId = data.id;
        this.isGlobal = data.isGlobal;
    }

    onBeforeUpload(evt) {
        evt.formData.append('ownedId', this.currentUser.userId);
        evt.formData.append('folderId', this.folderId);
        evt.formData.append('isGlobal', this.isGlobal);
    }

    onSelectFile() {
        this.fileControl.upload();
    }

    onUploadProgress(evt) {
        this.progressValue = evt.progress;
    }

    onUpload(event) {
        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }
    }

    onUploaded(evt) {
        this.closePopupMethod(true);
    }

}
