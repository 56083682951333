/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-found.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./not-found.component";
var styles_ErrorPageNotFoundComponent = [i0.styles];
var RenderType_ErrorPageNotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorPageNotFoundComponent, data: {} });
export { RenderType_ErrorPageNotFoundComponent as RenderType_ErrorPageNotFoundComponent };
export function View_ErrorPageNotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 404 - Trang b\u1EA1n t\u00ECm ki\u1EBFm kh\u00F4ng t\u1ED3n t\u1EA1i\n"]))], null, null); }
export function View_ErrorPageNotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-not-found", [], null, null, null, View_ErrorPageNotFoundComponent_0, RenderType_ErrorPageNotFoundComponent)), i1.ɵdid(1, 114688, null, 0, i2.ErrorPageNotFoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorPageNotFoundComponentNgFactory = i1.ɵccf("app-page-not-found", i2.ErrorPageNotFoundComponent, View_ErrorPageNotFoundComponent_Host_0, {}, {}, []);
export { ErrorPageNotFoundComponentNgFactory as ErrorPageNotFoundComponentNgFactory };
