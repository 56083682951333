import { Injectable } from '@angular/core';
import { SvUser } from '../models/sv-user';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import * as JWT from 'jwt-decode';
import { UserService } from './user.service';
@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    readonly storage = sessionStorage;


    constructor(
        private _oauthService: OAuthService,
        private _userService: UserService
    ) { }

    logout() {
        this.storage.clear();
        // Object.keys(this.storage).filter(item => /^AUTHORIZATION/.test(item)).forEach(item => {
        //     this.storage.removeItem(item);
        // });

        this._userService.logOut();
        this._oauthService.logOut();
    }

    setCurrentUser(jwtDecode) {
        const key = 'AUTHORIZATION.USERINFO';
        if (jwtDecode !== null && jwtDecode !== undefined) {
            const result = new SvUser();
            result.issuperuser = jwtDecode.issuperuser;
            result.userId = jwtDecode.userId;
            result.avatar = jwtDecode.avatar;
            result.fullName = jwtDecode.displayName;
            result.displayName = jwtDecode.displayName;
            this.storage.setItem(key, JSON.stringify(result));
        }
    }

    getCurrentUser(): SvUser {
        // const crrUser = new SvUser();
        const key = 'AUTHORIZATION.USERINFO';
        const storageItem = this.storage.getItem(key);
        // console.log(storageItem);

        if (storageItem) {
            const result = JSON.parse(storageItem) as SvUser;
            return result;
        } else {
            const accessToken = this._oauthService.getAccessToken();
            if (accessToken !== null) {
                const decoded: any = JWT(accessToken);
                const user = new SvUser();
                user.issuperuser = decoded.issuperuser;
                user.userId = decoded.userid;
                user.avatar = decoded.avatar;
                user.displayName = decoded.displayname;
                this.storage.setItem(key, JSON.stringify(user));
                return user;
            } else {
                return null;
            }
        }
    }

    getAccessToken() {
        return this._oauthService.getAccessToken();
    }
    isLoggedIn(): boolean {
        var crrUser = this.getCurrentUser();
        if (crrUser !== null && crrUser.userId > 0) {
            return true;
        }
        return false;
    }
}
