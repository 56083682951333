import { InjectionToken } from '@angular/core';
import * as i0 from "@angular/core";
export function moduleConfigFunc() {
    return new InjectionToken('');
}
export class ModuleConfigService {
    constructor(moduleConfigVal = null) {
        this._config = moduleConfigVal() ||
            {};
    }
    getConfig() {
        return this._config;
    }
}
ModuleConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModuleConfigService_Factory() { return new ModuleConfigService(i0.ɵɵinject(moduleConfigFunc, 8)); }, token: ModuleConfigService, providedIn: "root" });
