<div class="sv-app-topbar">
    <div class="__topbar-content-container">
        <div class="__topbar-left-content">
            <div class="__topbar-item">
                <div class="__topbar-action-btn __menu-collapse-toggle activated"
                    (click)="app.onMenuCollapseToggleClick()">
                    <span class="__icon state-active ci ci-close"></span>

                    <span class="__icon state-inactive ci ci-app-switcher"></span>
                </div>
            </div>

            <a class="__topbar-item __logo-wrapper" href="javascript:" (click)="app.onSwitchModule(1)">
                <img src="{{environment.appMetadata.main.logo}}" class="__logo" />
            </a>

            <div class="__topbar-item __information">
                <div class="__subsystem-name">
                    Hệ thống <span class="fw-bold">Quản lý và giám sát trạm đo</span>
                </div>
            </div>
        </div>

        <div class="__topbar-right-content">
            <!-- <div class="__topbar-item">
                <div class="__topbar-action-btn">
                    <span class="__icon ci ci-help"></span>
                </div>
            </div>

            <div class="__topbar-item">
                <div class="__topbar-action-btn">
                    <span class="__icon ci ci-settings"></span>
                </div>
            </div> -->
            <!-- <div class="__topbar-item sv-custom-overlay-panel-wrapper" (clickOutside)="closeSubsystemsDropdown($event)">
                <div class="__topbar-action-btn" (click)="toggleSubsystemsDropdown()"
                    [class.activated]="showDropdown.subsystem">
                    <span class="__icon ci ci-grid"></span>
                </div>

                <div class="sv-custom-overlay-panel bottom-left __subsystems-container"
                    [hidden]="!showDropdown.subsystem">
                    <div class="__subsystem-item" *ngFor="let item of subsystems">
                        <div class="__icon" [ngClass]="item.icon"></div>

                        <div class="__subtitle">Phân hệ</div>

                        <div class="__title">{{item.title}}</div>
                    </div>

                    <div class="__placeholder" *ngIf="subsystems.length % 2"></div>
                </div>
            </div> -->

            <div class="__topbar-item sv-custom-overlay-panel-wrapper"
                (clickOutside)="closeUserSettingDropdown($event)">
                <div class="__topbar-action-btn" (click)="toggleUserSettingDropdown()"
                    [class.activated]="showDropdown.userSetting">
                    <span class="__icon ci ci-user-avatar"></span>
                </div>

                <div class="sv-custom-overlay-panel bottom-left __user-setting-container"
                    [hidden]="!showDropdown.userSetting">
                    <div class="__user-info-wrapper">
                        <div class="__user-info">
                            <img class="__avatar" src="{{avatarUrl}}" alt="avatar" />

                            <div class="__name">{{displayName}}</div>

                            <div class="__role">{{position}}</div>
                        </div>
                    </div>

                    <div class="__action-menu-container">
                        <a href="/core/myprofile" class="__action-menu-item">
                            <i class="__icon ci ci-user-admin"></i>
                            <span class="__title">Thông tin cá nhấn</span>
                        </a>

                        <a href="/core/myprofile" class="__action-menu-item">
                            <i class="__icon ci ci-fingerprint-recognition"></i>
                            <span class="__title">Thay đổi mật khẩu</span>
                        </a>

                        <a href="javascript:void(0);" class="__action-menu-item">
                            <i class="__icon ci ci-notification"></i>
                            <span class="__title">Thiết lập thông báo</span>
                        </a>

                        <a href="javascript:void(0);" class="__action-menu-item" (click)="app.onTopbarLogout()">
                            <i class="__icon ci ci-power"></i>
                            <span class="__title">Đăng xuất</span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="__topbar-item" (clickOutside)="closeRightSidebar($event)">
                <div class="__topbar-action-btn highlight" (click)="toggleRightSidebar()" style="width: 100%;">
                    <span class="__icon ci ci-event-schedule"></span>({{totalUnRead}})
                </div>

                <ng-container *ngTemplateOutlet="rightSideBar"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #rightSideBar>
    <div class="sv-right-sidebar-area" [ngClass]="{
                    'show': showDropdown.rightSidebar,
                    'hide': !showDropdown.rightSidebar
                    }">
        <div class="__sidebar-tabs-container">
            <div class="__tab-item" [class.activated]="currentSidebarTab === SidebarTabs.NOTIFICATION"
                (click)="currentSidebarTab = SidebarTabs.NOTIFICATION">
                Thông báo
            </div>

            <div class="__tab-item" [class.activated]="currentSidebarTab === SidebarTabs.ACTIVITY"
                (click)="currentSidebarTab = SidebarTabs.ACTIVITY">
                Hoạt động
            </div>
        </div>

        <div class="__sidebar-panel-container">
            <div class="__notification-panel" [hidden]="currentSidebarTab !== SidebarTabs.NOTIFICATION">
                <!-- <app-notification></app-notification> -->
                <div class="sv-notification-container">
                    <ng-template ngFor let-group [ngForOf]="notifications" *ngIf="hasChildren()">
                        <div class="__group-label">{{group.label}}</div>

                        <ng-template ngFor let-item [ngForOf]="group.items">
                            <div class="__notification-item" [class.unseen]="item.unseen">
                                <div class="__icon ci" [ngClass]="{
                                        'ci-chat': item.type === 1,
                                        'ci-bookmark': item.type === 2,
                                        'ci-edit': item.type === 3,
                                        'ci-notification': item.type === 4
                                    }"></div>

                                <div class="__title">{{item.tieuDe}}</div>

                                <div class="__time">{{item.created | date:'dd/MM/yyyy HH:mm'}}</div>
                            </div>
                        </ng-template>
                    </ng-template>

                    <ng-template ngFor let-item [ngForOf]="notifications" *ngIf="!hasChildren()">
                        <div class="__notification-item" [class.unseen]="item.unseen" (click)="readMessage(item.id)">
                            <div class="__icon ci" [ngClass]="{
                                        'ci-chat': item.type === 1,
                                        'ci-bookmark': item.type === 2,
                                        'ci-edit': item.type === 3,
                                        'ci-notification': item.type === 4 || item.type === null || item.type === undefined
                                    }"></div>

                            <div class="__title">{{item.tieuDe}}</div>

                            <div class="__time">{{item.created | date:'dd/MM/yyyy HH:mm'}}</div>
                        </div>
                    </ng-template>
                </div>
            </div>

            <div class="__activity-panel" [hidden]="currentSidebarTab !== SidebarTabs.ACTIVITY">
                <div class="py-8 px-16">
                    Danh sách hoạt động
                </div>
            </div>
        </div>
    </div>
</ng-template>