import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { environment } from '../../../../../src/environments/environment';
import { CommonService } from './common.service';
import { catchError } from 'rxjs/operators';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const apiEndpoint = environment.apiDomain.coreEndPoint + '/Export';

@Injectable({
    providedIn: 'root'
})
export class ExportService {
    _http: HttpClient;

    constructor(
        private http: HttpClient,
        private _commonService: CommonService,
        private _injector: Injector
    ) {
        this._http = http;
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    export(data: any[], cols: any[], fileName: string) {
        const obj = {
            data: data,
            cols: cols
        };
        return this._http
            .post(`${apiEndpoint}/export`, obj, { responseType: 'blob' as 'json' })
            .pipe(catchError((err: HttpErrorResponse) => this._commonService.handleError(err, this._injector)))
            .subscribe(res => {
                this.saveAsExcelFile(res, fileName);
            });
    }
}
