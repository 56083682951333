import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../src/environments/environment';
import { BaseService } from './base.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FsFoldersService extends BaseService {
    constructor(http, injector) {
        super(http, injector, `${environment.apiDomain.coreEndPoint}/fsfolders`);
    }
    getsChildFolder(idFsFolderCha, isGlobal, trangThai) {
        const url = `${this.serviceUri}/GetsChildFolder?idFsFolderCha=${idFsFolderCha}&isGlobal=${isGlobal}&trangThai=${trangThai}`;
        return this.defaultGet(url);
    }
    createFolder(parentFolderId, isGlobal, ten) {
        const url = `${this.serviceUri}/CreateFolder?parentFolderId=${parentFolderId}&isGlobal=${isGlobal}&ten=${ten}`;
        return this._http
            .post(url, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    getTree(isGlobal, trangThai) {
        const url = `${this.serviceUri}/GetTree?isGlobal=${isGlobal}&trangThai=${trangThai}`;
        return this.defaultGet(url);
    }
}
FsFoldersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FsFoldersService_Factory() { return new FsFoldersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i0.INJECTOR)); }, token: FsFoldersService, providedIn: "root" });
