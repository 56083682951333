var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { environment } from '../../../environments/environment';
import { HtMenuService } from 'sv-shared';
export class AppMenuSearchBoxComponent {
    constructor(_router, _menuService) {
        this._router = _router;
        this._menuService = _menuService;
        this.arrMenu = [];
        this.isMenuSearchInputFocus = false;
    }
    ngOnInit() {
        this.getMenu();
    }
    onMenuSearch(event) {
        this.menuSearchResult = [];
        if (!event || !event.query) {
            return;
        }
        this.menuSearchResult = this.getMenuSearchResult(event.query);
    }
    getMenu() {
        return __awaiter(this, void 0, void 0, function* () {
            const cacheVal = sessionStorage.getItem('htMenu' + environment.clientDomain.idPhanhe.toString());
            if (cacheVal !== undefined && cacheVal !== null) {
                this.arrMenu = yield JSON.parse(cacheVal);
            }
            else {
                yield this._menuService.getByIdPhanHe(environment.clientDomain.idPhanhe, 0).then(rs => {
                    if (rs.success) {
                        this.arrMenu = rs.data;
                        sessionStorage.setItem('htMenu' + environment.clientDomain.idPhanhe.toString(), JSON.stringify(rs.data));
                    }
                });
            }
        });
    }
    getMenuSearchResult(keyword) {
        let result = [];
        this.regroupMenuSearchResult(keyword, this.arrMenu, result);
        return result;
    }
    regroupMenuSearchResult(keyword, listToGet, listToPush) {
        listToGet.forEach(item => {
            if (item.items && item.items.length) {
                this.regroupMenuSearchResult(keyword, item.items, listToPush);
            }
            if (item.trangThai === 1
                && (item.label.toUpperCase().indexOf(keyword.toUpperCase()) >= 0)) {
                listToPush.push(item);
            }
        });
    }
    onSelectMenuSuggestion(menuItemObj) {
        this._router.navigateByUrl(menuItemObj.routerLink);
        this.menuSearchInput = '';
    }
    onBlurMenuSearchInput(event) {
        this.isMenuSearchInputFocus = false;
        this.menuSearchInput = '';
    }
    onFocusMenuSearchInput(event) {
        this.isMenuSearchInputFocus = true;
    }
}
