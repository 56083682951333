import { SvUser } from '../models/sv-user';
import { OAuthService } from 'angular-oauth2-oidc';
import * as JWT from 'jwt-decode';
import { UserService } from './user.service';
import * as i0 from "@angular/core";
import * as i1 from "angular-oauth2-oidc";
import * as i2 from "./user.service";
export class AuthenticationService {
    constructor(_oauthService, _userService) {
        this._oauthService = _oauthService;
        this._userService = _userService;
        this.storage = sessionStorage;
    }
    logout() {
        this.storage.clear();
        // Object.keys(this.storage).filter(item => /^AUTHORIZATION/.test(item)).forEach(item => {
        //     this.storage.removeItem(item);
        // });
        this._userService.logOut();
        this._oauthService.logOut();
    }
    setCurrentUser(jwtDecode) {
        const key = 'AUTHORIZATION.USERINFO';
        if (jwtDecode !== null && jwtDecode !== undefined) {
            const result = new SvUser();
            result.issuperuser = jwtDecode.issuperuser;
            result.userId = jwtDecode.userId;
            result.avatar = jwtDecode.avatar;
            result.fullName = jwtDecode.displayName;
            result.displayName = jwtDecode.displayName;
            this.storage.setItem(key, JSON.stringify(result));
        }
    }
    getCurrentUser() {
        // const crrUser = new SvUser();
        const key = 'AUTHORIZATION.USERINFO';
        const storageItem = this.storage.getItem(key);
        // console.log(storageItem);
        if (storageItem) {
            const result = JSON.parse(storageItem);
            return result;
        }
        else {
            const accessToken = this._oauthService.getAccessToken();
            if (accessToken !== null) {
                const decoded = JWT(accessToken);
                const user = new SvUser();
                user.issuperuser = decoded.issuperuser;
                user.userId = decoded.userid;
                user.avatar = decoded.avatar;
                user.displayName = decoded.displayname;
                this.storage.setItem(key, JSON.stringify(user));
                return user;
            }
            else {
                return null;
            }
        }
    }
    getAccessToken() {
        return this._oauthService.getAccessToken();
    }
    isLoggedIn() {
        var crrUser = this.getCurrentUser();
        if (crrUser !== null && crrUser.userId > 0) {
            return true;
        }
        return false;
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.OAuthService), i0.ɵɵinject(i2.UserService)); }, token: AuthenticationService, providedIn: "root" });
