import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class HeartBeatService {
    constructor(_http) {
        this._http = _http;
        this.listFailedHeartBeat = [];
    }
    sendHeartBeat() {
        for (const key in environment.apiDomain) {
            if (this.listFailedHeartBeat.findIndex(x => x === key) > -1) {
                continue;
            }
            // const infoUrl = `${environment.apiDomain[key]}/info`;
            // this._http.get(infoUrl).toPromise()
            //     .then(rs => {
            //         this.listFailedHeartBeat.splice(this.listFailedHeartBeat.findIndex(x => x === key), 1);
            //     })
            //     .catch(err => {
            //         this.listFailedHeartBeat.push(key);
            //     });
        }
    }
}
HeartBeatService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HeartBeatService_Factory() { return new HeartBeatService(i0.ɵɵinject(i1.HttpClient)); }, token: HeartBeatService, providedIn: "root" });
