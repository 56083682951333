var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OnInit, Injector } from '@angular/core';
import { PermissionService, SecondPageIndexBase } from 'sv-shared';
import { MeasurementStationsService } from '../lasi/services/measurement-stations.service';
import { MeasurementValuesService } from '../lasi/services/measurement-values.service';
import { map, takeUntil } from 'rxjs/operators';
export class DashboardComponent extends SecondPageIndexBase {
    constructor(_permissionService, _measurementstationsService, _measurementValuesService, _injector) {
        super(_measurementstationsService, _injector);
        this._permissionService = _permissionService;
        this._measurementstationsService = _measurementstationsService;
        this._measurementValuesService = _measurementValuesService;
        this._injector = _injector;
        this.cols = [];
        this.itemTramDo = { id: 0, ma: '', ten: '', tenTinhTrangHoatDong: 0, diaChi: '', caoDo: '', gps: '' };
        this.itemTinhTrangHoatDong = { label: '', value: 0 };
        this.arrSoLieuDo = [];
        this.arrTramDo = [];
        this.idTramDo = 0;
    }
    ngOnInit() {
        return __awaiter(this, void 0, void 0, function* () {
            this.cols = [
                { field: 'ten', header: this._translateService.instant('Tên'), visible: true, sort: true, width: 'auto', },
                { field: 'tenTinhTrangHoatDong', header: this._translateService.instant('Hoạt động'), visible: true, width: 'auto', },
                { field: 'mucDienAp', header: this._translateService.instant('Mức điện áp'), visible: true, width: 'auto', },
                { field: 'gps', header: this._translateService.instant('Tọa độ GPS'), visible: true, width: 'auto', },
            ];
            this.pageSize = 5;
            yield this.getData();
            yield this._activatedRoute.params
                .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
                .subscribe(pid => {
                if (pid !== undefined) {
                    this.idTramDo = pid;
                }
            });
            this.getSoLieuDoGanNhat();
        });
    }
    getData() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isLoading = true;
            var isLaSiAdmin = false;
            yield this._permissionService.isLaSiAdmin().then(rs => {
                isLaSiAdmin = rs;
            });
            var searchModel = {
                keyword: '',
                trangThai: 1,
                idCategory: 0,
                idCustomer: 0,
                tinhTrangHoatDong: 0,
                isLaSiAdmin: isLaSiAdmin,
                pageIndex: this.pageIndex,
                pageSize: 9999,
                orderCol: this.orderCol ? this.orderCol : 'Id',
                isDesc: this.isDesc,
            };
            this.arrTramDo = [];
            yield this._measurementstationsService.find(searchModel)
                .then(response => {
                this.dataSource = response.data;
                this.convertDataToOptions(this.arrTramDo, response.data, 'ten');
                this.totalRecord = response.totalRecord;
                this.idTramDo = this.dataSource[0].id;
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
                this._notifierService.showHttpUnknowError();
            });
        });
    }
    getSoLieuDoGanNhat() {
        this.itemTramDo = this.dataSource.filter(x => Number(x.id) === Number(this.idTramDo))[0];
        this.arrSoLieuDo = [];
        if (this.idTramDo > 0) {
            this._measurementValuesService.getMapViewByIdMeasurementStation(this.idTramDo).then(rs => {
                if (rs.success) {
                    this.arrSoLieuDo = rs.data.filter(x => x.ma !== '0800');
                    this.itemDienAp = rs.data.filter(x => x.ma === '0800')[0];
                    this.itemTramDo.tenTinhTrangHoatDong = rs.data[0].tenTinhTrangHoatDongDuLieuTram;
                }
            });
        }
    }
    onChangeTramDo() {
        this.getSoLieuDoGanNhat();
    }
}
