<div class="sv-page-login-container">
    <div class="sv-page-login-body-area">
        <div class="sv-page-login-header">
            <a class="__logo-wrapper" href="javascript:void(0)">
                <img src="{{environment.appMetadata.main.logo}}" class="__logo" />
            </a>

            <div class="__right-content">
                <div class="__title">{{environment.appMetadata.main.owner}}</div>

                <div class="__slogan">{{environment.appMetadata.main.title}}</div>
            </div>
        </div>

        <div class="sv-page-login-content">
            <router-outlet></router-outlet>
        </div>

        <div class="sv-page-login-footer">
            <!-- <div class="cursor-pointer">{{'Facebook' | translate}}</div>
            <div class="cursor-pointer">{{'Web' | translate}}</div> -->
        </div>
    </div>
</div>
<p-toast class="sv-toast" position="bottom-right" key="success-warn"></p-toast>