import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../src/environments/environment';
import { BaseService } from './base.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FsFilesService extends BaseService {
    constructor(http, injector) {
        super(http, injector, `${environment.apiDomain.coreEndPoint}/fsfiles`);
    }
    getsByFolderId(idFsFolder, isGlobal, trangThai) {
        const url = `${this.serviceUri}/GetsByFolderId?idFsFolder=${idFsFolder}&isGlobal=${isGlobal}&trangThai=${trangThai}`;
        return this.defaultGet(url);
    }
    getUploadUrl() {
        return `${this.serviceUri}/UploadFiles`;
    }
    getAnonymousDownloadUrl(instalceId) {
        return `${environment.apiDomain.coreEndPoint}/FsFilesDownload/${instalceId}`;
    }
    isSupportedViewOnline(fileName) {
        // file không có đuổi -> loại
        if (fileName.lastIndexOf('.') === -1) {
            return false;
        }
        const fileType = fileName.substring(fileName.lastIndexOf('.') + 1);
        if ((/(gif|jpg|jpeg|tiff|png)$/i).test(fileType)) {
            return true;
        }
        else if ((/(doc|docm|docx|dot|dotm|dotx|epub|fodt|htm|html|mht|odt|ott|pdf|rtf|txt|djvu|xps)$/i).test(fileType)) {
            return true;
        }
        else if ((/(csv|fods|ods|ots|xls|xlsm|xlsx|xlt|xltm|xltx)$/i).test(fileType)) {
            return true;
        }
        else if ((/(fodp|odp|otp|pot|potm|potx|pps|ppsm|ppsx|ppt|pptm|pptx)$/i).test(fileType)) {
            return true;
        }
        return false;
    }
}
FsFilesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FsFilesService_Factory() { return new FsFilesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i0.INJECTOR)); }, token: FsFilesService, providedIn: "root" });
